import { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';

import RegisterUnconfirmedSessionComponent from "./RegisterUnconfirmedSession";
import useFormWithDisable from '../../../../auxiliar/customHooks/useFormWithDisable';
import { postUnconfirmedSession } from "../../../../actions/unconfirmedSession";
import { onClosePopup, onOpenPopup } from '../../../../auxiliar/auxFunctions';

const RegisterUnconfirmedSessionPopup = ({
    childFunc,
    refresh,
}) => {
    const ref = useRef();
    const openTooltip = () => ref.current.open();
    const closeTooltip = () => ref.current.close();

    const { register, watch, handleSubmit, resetForm, setValue, isSubmitting, errors } =
        useFormWithDisable();

    const [eventData, setEventData] = useState({})

    useEffect(() => {
        childFunc.current = (data) => {
            resetForm();
            setEventData(data);
            openTooltip();
        };
    }, []);

    const clean = () => {
        refresh()
        setEventData({})
    }

    return <Popup
        onClose={onClosePopup(clean)}
        onOpen={onOpenPopup()}
        ref={ref}
        modal
        position="bottom center"
        lockScroll
        nested
        closeOnDocumentClick={false}
        contentStyle={{ margin: "50px auto", width: "95%", maxHeight: "90%" }}
    >
        <div
            className="p-5 bg-base-100 rounded-lg scrollbar-thumb-rounded-full
                    scrollbar-track-rounded-full scrollbar-thin scrollbar-thumb-base-300
                    scrollbar-track-base-100 overflow-x-hidden"
            style={{ width: "100%", maxHeight: "100%", overflowY: "auto" }}
        >
            <div className={"p-5 bg-base-100 rounded-lg"}>
                <h2 className="text-primary text-2xl">Solicitud de sesión</h2>

                <p className="lg:text-lg my-3">
                    Si eres un cliente habitual de la clínica te recomendamos que introduzcas los datos con
                    los que podamos identificarte (email y teléfono de contacto). De esta forma podremos
                    encontrar tu ficha y añadir esta sesión a tu historial.
                </p>
                
                <RegisterUnconfirmedSessionComponent
                    eventData={eventData}
                    closeTooltip={closeTooltip}
                    register={register}
                    errors={errors}
                    watch={watch}
                    setValue={setValue}
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                />

                <div className="mt-5 flex space-x-2 sm:space-x-4">
                    <button
                        className="btn btn-primary btn-sm"
                        disabled={isSubmitting}
                        onClick={handleSubmit(
                            postUnconfirmedSession,
                            closeTooltip
                        )}
                    >
                        Confirmar
                    </button>

                    <button
                        className="btn btn-primary btn-sm"
                        onClick={closeTooltip}
                        disabled={isSubmitting}
                    >
                        Cancelar
                    </button>
                </div>
            </div>
        </div>
    </Popup>
};


export default RegisterUnconfirmedSessionPopup