import { createRoot } from "react-dom/client";

import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import ModalNewVersion from "./components/general/auxiliar/modal/ModalNewVersion";

import 'kalend/dist/styles/index.css';
import "./styles/output.css";
import "./styles/overlay.css";
import "./styles/kalend.css";
import "./styles/smallCalendar.css";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<>
		{/* StrictMode */}
		<div id="overlay"></div>
		<div id="portal-root"></div>
		<App />
		<ModalNewVersion id="new-version-modal" />
	</>
);

// Register the service worker to enable automatic updates
serviceWorkerRegistration.register();

// Listen for Service Worker updates and handle them automatically
if ('serviceWorker' in navigator) {
	let refreshing = false;
	let updateTimeout = null;
	let currentVersion = process.env.REACT_APP_GIT_COMMIT || 'development';

	// Function to show update modal
	const handleUpdate = () => {
		if (refreshing) return;
		refreshing = true;

		try {
			// Show the update modal
			const modal = document.getElementById('new-version-modal');
			if (modal) {
				modal.showModal();
			} else {
				console.warn('Update modal not found');
				// If modal is not found, try to reload the page
				window.location.reload();
			}
		} catch (error) {
			console.error('Error showing update modal:', error);
			// If showing modal fails, try to reload the page
			window.location.reload();
		}
	};

	// Function to verify service worker message
	const verifyServiceWorkerMessage = (event) => {
		// Verify the message is from our service worker
		if (!event.source || !event.source.url.includes(window.location.origin)) {
			console.warn('Received message from unknown source:', event.source);
			return false;
		}

		// Verify the message type
		if (!event.data || !['NEW_VERSION', 'NEW_VERSION_WAITING'].includes(event.data.type)) {
			console.warn('Received unknown message type:', event.data?.type);
			return false;
		}

		return true;
	};

	// Listen for messages from the service worker
	navigator.serviceWorker.addEventListener('message', (event) => {
		if (verifyServiceWorkerMessage(event) && !refreshing) {
			// Clear any pending update timeout
			if (updateTimeout) {
				clearTimeout(updateTimeout);
			}
			handleUpdate();
		}
	});

	// Check if there's a waiting service worker
	navigator.serviceWorker.ready.then(registration => {
		if (registration.waiting) {
			handleUpdate();
		}
	}).catch(error => {
		console.error('Error checking service worker status:', error);
		// Don't reload on non-critical errors
	});

	// Listen for new service worker registration
	navigator.serviceWorker.addEventListener('controllerchange', () => {
		handleUpdate();
	});

	// Listen for service worker errors
	navigator.serviceWorker.addEventListener('error', (error) => {
		console.error('Service worker error:', error);
		// Only reload on critical errors that affect functionality
		if (error.message?.includes('fetch') || error.message?.includes('network')) {
			window.location.reload();
		}
	});

	// Listen for reset update flag event
	window.addEventListener('resetUpdateFlag', () => {
		refreshing = false;
	});
}

// Measure performance
reportWebVitals();