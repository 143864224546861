import { useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";

import RegisterSession from "./RegisterSession";
import RegisterClass from "./RegisterClass";
import RegisterAbsence from "./RegisterAbsence";
import CustomForm from "../../../general/form/CustomForm";
import useFormWithDisable from "../../../../auxiliar/customHooks/useFormWithDisable";
import { onClosePopup, onOpenPopup } from "../../../../auxiliar/auxFunctions";

const titleText = {
    individual: "Registro de una nueva sesión",
    group: "Registro de una nueva clase",
    block: "Registro de una nueva ausencia",
}

const RegisterSessionPopup = ({ childFunc, refresh }) => {
    const ref = useRef();
    const openTooltip = () => ref.current.open();
    const closeTooltip = () => ref.current.close();

    const { register, watch, handleSubmit, resetForm, setValue, isSubmitting, errors } =
        useFormWithDisable();

    const [individualBlocked, setIndividualBlocked] = useState(false);

    const formConfig = {
        type: {
            condition: true,
            disabled: individualBlocked,
            type: "selector",
            label: "Tipo",
            name: "type",
            validators: {
                required: "Elegir un tipo es obligatorio",
            },
            options: [
                { key: "individual", label: "Sesión individual" },
                { key: "group", label: "Clase" },
                { key: "block", label: "Ausencia" },
            ],
        },
    };

    const [eventData, setEventData] = useState({});

    const type = individualBlocked ? "individual" : watch("type") || "individual";

    useEffect(() => {
        childFunc.current = (data, individualBlocked = false) => {
            resetForm();
            setEventData(data);
            setIndividualBlocked(individualBlocked);
            openTooltip();

            if (individualBlocked) {
                setValue("type", "individual");
            }
        };
    }, []);

    const clean = () => {
        refresh()
        setEventData({})
        setIndividualBlocked(false)
    }

    return (
        <Popup
            onClose={onClosePopup(clean)}
            onOpen={onOpenPopup()}
            ref={ref}
            modal
            position="bottom center"
            lockScroll
            nested
            closeOnDocumentClick={false}
            contentStyle={{ margin: "20px auto", width: "95%", maxHeight: "95%" }}
        >
            <div
                className="p-5 bg-base-100 rounded-lg scrollbar-thumb-rounded-full
                    scrollbar-track-rounded-full scrollbar-thin scrollbar-thumb-base-300
                    scrollbar-track-base-100 overflow-x-hidden"
                style={{ width: "100%", maxHeight: "100%", overflowY: "auto" }}
            >
                <div className={"p-5 bg-base-100 rounded-lg"}>
                    <h2 className="text-primary text-2xl">
                        {titleText[type || "individual"]}
                    </h2>

                    <div className="mt-3">
                        <form className={"grid grid-cols-1 gap-3 mb-3"}>
                            <CustomForm
                                formConfig={formConfig}
                                errors={errors}
                                register={register}
                            />
                        </form>

                        {type === "individual" && (
                            <RegisterSession
                                errors={errors}
                                register={register}
                                watch={watch}
                                setValue={setValue}
                                isSubmitting={isSubmitting}
                                handleSubmit={handleSubmit}
                                closeTooltip={closeTooltip}
                                eventData={eventData}
                            />
                        )}

                        {type === "group" && (
                            <RegisterClass
                                errors={errors}
                                register={register}
                                watch={watch}
                                setValue={setValue}
                                isSubmitting={isSubmitting}
                                handleSubmit={handleSubmit}
                                closeTooltip={closeTooltip}
                                eventData={eventData}
                            />
                        )}

                        {type === "block" && (
                            <RegisterAbsence
                                errors={errors}
                                register={register}
                                watch={watch}
                                setValue={setValue}
                                isSubmitting={isSubmitting}
                                handleSubmit={handleSubmit}
                                closeTooltip={closeTooltip}
                                eventData={eventData}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default RegisterSessionPopup;
