import { useState } from "react";
import { useDispatch } from "react-redux";

import { dispatchMessageSave } from "../../store/reducers/messageReducer";
import { handleSubmit } from "../../auxiliar/auxFunctions";

const ShowManagerReferral = ({ code }) => {
	const path = `${process.env.REACT_APP_APP_URL}/landing-page?referral_code=${code}`

	const dispatch = useDispatch();
	const [copied, setCopied] = useState(false);

	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(path)
			.then(() => {
				setCopied(true);
				dispatchMessageSave(dispatch, {
					msg: "¡Enlace copiado! Compártelo con tus amigos y gana meses gratis",
					type: "info",
				});
			})
			.catch(() => {
				dispatchMessageSave(dispatch, {
					msg: "Inténtalo de nuevo, ha habido un error al copiar el enlace",
					type: "error",
				});
			});
	};

	return (
		<div className="my-5">
			<h3 className="text-primary text-2xl">Invita y disfruta de MiFisio gratis</h3>

			<p className="lg:text-lg my-4">
				En MiFisio Agenda queremos que más fisioterapeutas descubran lo fácil que es gestionar su clínica sin complicaciones. Por eso, hemos creado nuestro <strong>programa de referidos</strong>, donde tú y tus amigos ganáis.
			</p>

			<p className="lg:text-lg my-1">
				<strong>¿Cómo funciona?</strong>
			</p>
			<ul className="list-decimal list-inside">
				<li>
					Comparte tu enlace único con otros fisioterapeutas.
				</li>
				<li>
					Cuando se registren a través de tu enlace, ambos recibiréis <strong>un mes gratis</strong> de MiFisio Agenda.
				</li>
				<li>
					<strong>¡Sin límites!</strong> Cuantos más amigos invites, más meses gratis consigues.
				</li>
			</ul>


			<p className="lg:text-lg my-4">
				<strong>Copiar tu enlace es muy fácil</strong>, solo haz clic en el botón de abajo y se guardará automáticamente en tu portapapeles. ¡Empieza a compartir y ahorra en tu suscripción hoy mismo! 🚀
			</p>

			<div className="flex flex-col items-center">
				<button
					className={`btn ${copied ? "btn-primary" : "btn-secondary"}`}
					onClick={handleSubmit(copyToClipboard)}
				>
					{copied ? "¡Copiado!" : "Copiar enlace"}
				</button>
			</div>
		</div>
	);
};

export default ShowManagerReferral;
