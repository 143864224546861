import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Collapse from "../../general/auxiliar/collapse/Collapse";
import PaginatorComponent from "../../general/auxiliar/PaginatorComponent";
import OptionSelector from "../../general/auxiliar/OptionSelector";
import CustomForm from "../../general/form/CustomForm";
import usePaginationFilterEffects from "../../../auxiliar/customHooks/usePaginationFilterEffects";
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import { getAllSessionTypes } from "../../../actions/sessionTypes";
import { handleSubmit } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";
import { colors } from "../../../auxiliar/colors";

const OverviewSessionTypes = ({ options }) => {
	const navigate = useNavigate();
	const { id: clinicId } = useSelector((state) => state.activeClinic);

	const { register, watch, errors } = useFormWithDisable();

	const [isCollapseOpen, setIsCollapseOpen] = useState(false);
	const [sessionTypes, setSessionTypes] = useState({ waiting: true });
	const [filter, setFilter] = useState({
		clinic_id: clinicId,
		order: "name",
		per_page: 10,
		page: 1,
	});

	const writtenFilter = {
		name: watch("name") ?? "",
	};

	const disabled = usePaginationFilterEffects(
		filter,
		isCollapseOpen ? writtenFilter : undefined,
		getAllSessionTypes,
		setSessionTypes,
		setFilter
	);

	const formConfig = {
		name: {
			condition: isCollapseOpen,
			disabled: false,
			type: "text",
			label: "Nombre",
			placeholder: "Nombre",
			name: "name",
		},
	};

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<div className="relative">
				<div className="absolute right-0 top-0 z-10">
					<OptionSelector options={options} />
				</div>
				<Collapse
					isMainTitle={true}
					title="Sesiones"
					faModality="filter"
					onStateChange={setIsCollapseOpen}
				>
					<form className="mt-3">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
							<CustomForm
								formConfig={formConfig}
								errors={errors}
								register={register}
							/>
						</div>
					</form>
				</Collapse>
			</div>

			<div className="sm:container p-3 mx-auto my-2">
				{sessionTypes.waiting ? (
					<div className="flex items-center justify-center">
						<span className="loading loading-dots loading-lg" />
					</div>
				) : !isDefined(sessionTypes.order) ||
					!isDefined(sessionTypes.content) ||
					sessionTypes.order.length <= 0 ? (
					<p className="lg:text-lg my-3">
						No hay tipos de sesión registradas, añade la primera haciendo click en
						el botón de abajo.
					</p>
				) : (
					<div className="overflow-x-auto overflow-y-hidden">
						<table className="table">
							<thead>
								<tr>
									<th>Nombre</th>
									<th className="hidden md:table-cell">Duración</th>
									<th className="hidden sm:table-cell">Precio</th>
									<th className="hidden xl:table-cell">Color</th>
									<th />
								</tr>
							</thead>

							<tbody>
								{sessionTypes.order.map((id) => {
									const sessionType =
										sessionTypes.content && sessionTypes.content[id];

									return (
										<tr key={id}>
											<td className="max-w-xs">{sessionType.name}</td>
											<td className="hidden md:table-cell">
												{sessionType.session_duration} minutos
											</td>
											<td className="hidden sm:table-cell">
												{Number(sessionType.price).toFixed(2)}€
											</td>
											<td className="hidden xl:table-cell">
												{colors[sessionType.color]}
											</td>
											<td className="text-center">
												<button
													type="button"
													className="btn btn-primary btn-sm"
													onClick={handleSubmit(
														navigate,
														`/services/session-type/${id}`
													)}
												>
													Detalles
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}

				<form className="mt-5 flex justify-between">
					<div className="flex space-x-2 sm:space-x-4">
						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmit(navigate, "/services/session-type/register")}
						>
							Nuevo tipo de sesión
						</button>
					</div>

					<PaginatorComponent
						pagination={sessionTypes.pagination}
						setFilter={setFilter}
						filter={filter}
						disabled={disabled}
					/>
				</form>
			</div>
		</div>

	);
};

export default OverviewSessionTypes;
