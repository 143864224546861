
import Collapse from "./Collapse";

const VoucherInfoCollapse = () => {
    return (
        <Collapse
            title="¿Como funcionan los bonos?"
            children={<>
                <p className="mb-4">
                    Los bonos permiten a los pacientes pagar varias sesiones por adelantado. Se configuran aquí, pero deben ser adquiridos desde el perfil de cada paciente. También pueden compartirse entre pacientes.
                </p>

                <p className="mb-2">
                    <strong>📌 Lógica de asignación de bonos:</strong>
                </p>

                <ul className="list-disc list-inside mb-4">
                    <li>
                        Si un bono tiene un límite de sesiones, se asignarán tantas sesiones como indique el límite, y cuando se agoten, no se podrán asignar más sesiones a ese bono.
                    </li>
                    <li>
                        Los bonos pueden utilizarse en sesiones con cualquier fisioterapeuta, no solo con el asignado. Sin embargo, es necesario asignar uno para una correcta contabilidad.
                    </li>
                    <li>
                        Los bonos caducados o inactivos no se asignan automáticamente ni pueden aplicarse manualmente.
                        Para hacer excepciones, es necesario editar sus fechas de inicio o caducidad.
                    </li>
                    <li>
                        Si un bono está configurado para asignación automática, se aplicará a todas las sesiones del tipo seleccionado siempre que esté vigente en la fecha de la sesión.
                    </li>
                    <li>
                        Aunque un bono se asigne automáticamente, también puede aplicarse manualmente a otras sesiones de otros tipos si fuera necesario.
                    </li>
                    <li>
                        Todos los bonos pueden ser compartidos entre pacientes. Una vez una sesión se ha asignado a un bono, por mucho que el paciente deje de tener acceso a ese bono, la sesión seguirá contando.
                    </li>
                    <li>
                        Si no se establece un límite de sesiones, el bono será ilimitado.
                    </li>
                </ul>
            </>}
        />
    )
}

export default VoucherInfoCollapse;