const ModalNewVersion = ({ id }) => {
    const handleConfirm = async () => {
        try {
            // Notify the service worker to skip waiting
            if (navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({ type: 'SKIP_WAITING' });
                
                // Wait a bit to ensure the message is processed
                await new Promise(resolve => setTimeout(resolve, 100));
            }

            // Reload the page
            window.location.reload();
        } catch (error) {
            console.error('Error during update:', error);
            // If reload fails, try a hard reload
            window.location.href = window.location.href;
        }
    };

    return (
        <dialog id={id} className="modal z-60">
            <div className="modal-box">
                <h3 className="font-bold text-lg text-primary">¡Nueva versión disponible!</h3>
                <p className="py-4">
                    Nuestra aplicación ha recibido su sesión de fisioterapia y ahora está en mejor forma que nunca. La actualización se realizará automáticamente.
                </p>
                <p className="py-2 text-sm text-base-content/80">
                    Si tienes alguna pregunta o sugerencia, escríbenos a <a href="mailto:info@mifisioagenda.com" className="link link-primary">info@mifisioagenda.com</a>.
                </p>

                <div className="modal-action">
                    <form method="dialog" className="mt-2 flex space-x-2 sm:space-x-4">
                        <button className="btn btn-primary btn-sm" onClick={handleConfirm}>
                            Confirmar
                        </button>
                    </form>
                </div>
            </div>
        </dialog>
    );
};

export default ModalNewVersion; 