import { useState } from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPerson, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";

import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";
import usePaginationFilterEffects from "../../../../auxiliar/customHooks/usePaginationFilterEffects";
import { getAllSessionsInVoucher } from "../../../../actions/voucher";
import { isDefined } from "../../../../auxiliar/formatValidators";


const DisplaySessionsInAcquiredVoucher = ({ acquiredVoucherId, refreshBool }) => {
	const { id: activeClinicId } = useSelector((state) => state.activeClinic);
	const [sessions, setSessions] = useState({ waiting: true });
	const [filter, setFilter] = useState({
		clinic_id: activeClinicId,
		acquired_voucher_id: acquiredVoucherId,
		order: "date",
		per_page: 5,
		page: 1,
	});

	const disabled = usePaginationFilterEffects(
		filter,
		undefined,
		getAllSessionsInVoucher,
		setSessions,
		setFilter,
		refreshBool
	);

	return (
		<div className={"my-3 mx-auto"}>
			<h3 className="text-primary text-2xl">Sesiones Asociadas</h3>

			{sessions.waiting ? (
				<div className="flex items-center justify-center">
					<span className="loading loading-dots loading-lg" />
				</div>
			) : !isDefined(sessions.order) ||
				!isDefined(sessions.content) ||
				sessions.order.length <= 0 ? (
				<p className="lg:text-lg my-3">
					No hay sesiones pagadas con este bono.
				</p>
			) : (
				<div className="overflow-x-auto overflow-y-hidden my-3">
					<table className="table">
						<thead>
							<tr>
								<th>Paciente</th>
								<th>Fecha</th>
								<th className="hidden md:table-cell">Fisioterapeuta</th>
								<th className="hidden sm:table-cell">Tipo</th>
								<th />
							</tr>
						</thead>

						<tbody>
							{sessions.order.map((sessionId) => {
								const session = sessions.content && sessions.content[sessionId];

								return (
									<tr key={sessionId}>
										<td>{session.patient_name}</td>
										<td>{session.session_date}</td>
										<td className="hidden md:table-cell">
											{session.employee_name}
										</td>
										<td className="hidden sm:table-cell">{session.session_type}</td>
										<td className="text-center">
											{session.type === 'group_session' ?
												<FontAwesomeIcon icon={faPeopleGroup} alt="Sesión grupal" /> :
												<FontAwesomeIcon icon={faPerson} alt="Sesión individual" />
											}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			)}

			<div className="mt-5 flex justify-between">
				<PaginatorComponent
					pagination={sessions.pagination}
					setFilter={setFilter}
					filter={filter}
					disabled={disabled}
				/>
			</div>
		</div>
	);
};

export default DisplaySessionsInAcquiredVoucher;
