import Popup from "reactjs-popup";
import { useEffect, useRef, useState } from "react";

import EditSession from "./EditSession";
import EditAbsence from "./EditAbsence";
import EditClass from "./EditClass";
import EditUnconfirmedSession from "./EditUnconfirmedSession";
import { onClosePopup, onOpenPopup } from "../../../../auxiliar/auxFunctions";

const EditSessionPopup = ({
	childFunc,
	refresh,
	openTooltipRegister,
}) => {
	const ref = useRef();
	const openTooltip = () => ref.current.open();
	const closeTooltip = () => ref.current.close();

	useEffect(() => {
		childFunc.current = (data) => {
			setSession({
				id: data.id,
				type: data.event_type,
			})

			openTooltip();
		};
	}, []);

	const [session, setSession] = useState({
		id: undefined,
		type: undefined
	});

	return (
		<Popup
			onClose={onClosePopup(refresh)}
			onOpen={onOpenPopup()}
			ref={ref}
			modal
			position="bottom center"
			lockScroll
			nested
			closeOnDocumentClick={false}
			contentStyle={{ margin: "20px auto", width: "95%", maxHeight: "95%" }}
		>
			<div
				className="p-5 bg-base-100 rounded-lg scrollbar-thumb-rounded-full
					scrollbar-track-rounded-full scrollbar-thin scrollbar-thumb-base-300 
					scrollbar-track-base-100 overflow-x-hidden"
				style={{ width: "100%", maxHeight: "100%", overflowY: "auto" }}
			>
				<div className={"p-5 bg-base-100 rounded-lg"}>
					{session.type === 'individual' && (
						<EditSession
							closeTooltip={closeTooltip}
							orgSessionId={session.id}
							openTooltipRegister={openTooltipRegister}
						/>
					)}

					{session.type === "group" && (
						<EditClass
							closeTooltip={closeTooltip}
							orgClassId={session.id}
						/>
					)}

					{session.type === "absence" && (
						<EditAbsence
							closeTooltip={closeTooltip}
							orgAbsenceId={session.id}
						/>
					)}

					{session.type === "unconfirmed_session" && (
						<EditUnconfirmedSession
							closeTooltip={closeTooltip}
							orgSessionId={session.id}
						/>
					)}
				</div>
			</div>
		</Popup>
	);
};

export default EditSessionPopup;
