import FormSelector from "./FormSelector";
import { isDefined } from "../../../auxiliar/formatValidators";
import { colors } from "../../../auxiliar/colors";

const CustomColorSelector = ({ customKey, register, config, errors }) => {
	const checkColorSelected = (value) => {
		if (!isDefined(value) || value === "select") {
			return "Elegir un color para mostrar los eventos en el calendario es obligatorio";
		}
	};

	const options = [
		{ key: "select", label: "Selecciona un color" },
		...Object.entries(colors).map(([key, label]) => ({ key, label })),
	];

	return (
		<FormSelector
			customKey={customKey}
			register={register}
			config={{
				condition: config.condition,
				disabled: config.disabled,
				type: "selector",
				name: config.name,
				label: config.label,
				info: config.info,
				options: options,
				defaultValue: "select",
				validators: {
					validate: checkColorSelected,
				},
			}}
			errors={errors}
		/>
	);
};

export default CustomColorSelector;
