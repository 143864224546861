import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import { faEuro } from "@fortawesome/free-solid-svg-icons";
import { faCreativeCommonsNcEu } from "@fortawesome/free-brands-svg-icons";

import CustomForm from "../general/form/CustomForm";
import PayWithCashRegister from "../payment/PayWithCashRegister";
import OptionSelector from "../general/auxiliar/OptionSelector";
import useScreenSize from "../../auxiliar/customHooks/useScreenSize";
import useFormWithDisable from "../../auxiliar/customHooks/useFormWithDisable";
import { postCharge } from "../../actions/charge";
import { onClosePopup, onOpenPopup } from "../../auxiliar/auxFunctions";
import { isDefined } from "../../auxiliar/formatValidators";

// TODO: allow payment with acquired voucher if its a session or a class
const RegisterChargePopup = ({
	originalValues,
	refresh,
	trigger = <button className={"btn btn-primary btn-sm"}>Cobrar</button>,
}) => {
	const ref = useRef();
	const closeTooltip = () => ref.current.close();

	const {
		register,
		handleSubmit,
		handleSubmitWithoutValidation,
		setValue,
		watch,
		resetForm,
		errors,
		isSubmitting,
	} = useFormWithDisable()

	const { width: windowWidth } = useScreenSize();
	const { id: clinicId } = useSelector((state) => state.activeClinic);
	const [display, setDisplay] = useState(
		Number(originalValues?.debt) > 0 || (Number(originalValues?.final_price) - Number(originalValues?.debt)) <= 0 ?
			"newCharge" :
			"newPayback"
	);

	useEffect(() => {
		const d = Number(originalValues?.debt) > 0 || (Number(originalValues?.final_price) - Number(originalValues?.debt)) <= 0 ?
			"newCharge" :
			"newPayback"

		setDisplay(d)
	}, [originalValues]);

	// Form configuration
	const formConfig = {
		patient_name_tmp: {
			condition: true,
			disabled: true,
			defaultValue: originalValues?.patient_name,
			type: "text",
			label: "Nombre",
			name: "patient_name_tmp",
		},
		patient_surnames_tmp: {
			condition: true,
			disabled: true,
			defaultValue: originalValues?.patient_surnames,
			type: "text",
			label: "Apellidos",
			name: "patient_surnames_tmp",
		},
		patient_email_tmp: {
			condition: true,
			disabled: true,
			defaultValue: originalValues?.patient_email,
			type: "text",
			label: "Correo Electrónico",
			name: "patient_email_tmp",
		},
		patient_phone_number_tmp: {
			condition: true,
			disabled: true,
			defaultValue: originalValues?.patient_phone_number,
			whatsAppLink: originalValues?.whatsAppLink,
			type: "text",
			label: "Número de teléfono",
			name: "patient_phone_number_tmp",
		},
		employee_id_tmp: {
			condition: true,
			disabled: true,
			defaultValue: originalValues?.employee_id,
			type: "physio_selector",
			label: "Fisioterapeuta *",
			name: "employee_id_tmp",
		},
		// Only one of the following three fields will be displayed
		voucher_type_id_tmp: {
			condition: isDefined(originalValues?.voucher_type_id),
			disabled: true,
			defaultValue: originalValues?.voucher_type_id,
			type: "acquired_voucher_selector",
			label: "Tipo de bono",
			name: "voucher_type_id_tmp",
		},
		session_type_id_tmp: {
			condition: isDefined(originalValues?.session_type_id),
			disabled: true,
			defaultValue: originalValues?.session_type_id,
			type: "session_type_selector",
			label: "Tipo de sesión",
			name: "session_type_id_tmp",
		},
		group_session_type_id_tmp: {
			condition: isDefined(originalValues?.group_session_type_id),
			disabled: true,
			defaultValue: originalValues?.group_session_type_id,
			type: "group_session_type_selector",
			label: "Tipo de sesión",
			name: "session_type_id_tmp",
		},
		entity_date_tmp: {
			condition: isDefined(originalValues?.entity_date),
			disabled: true,
			defaultValue: originalValues?.entity_date,
			type: "date",
			label: `Fecha de ${isDefined(originalValues?.session_type_id) && "sesión" ||
				isDefined(originalValues?.group_session_type_id) && "clase" ||
				isDefined(originalValues?.voucher_type_id) && "adquisición"}`,
			name: "entity_date_tmp",
		},
		issue_date_tmp: {
			condition: true,
			disabled: true,
			defaultValue: originalValues?.issue_date,
			type: "date",
			label: "Fecha de creación",
			name: "issue_date_tmp",
		},
	};

	// Options configuration
	const optionsConfig = {
		newCharge: {
			condition: Number(originalValues?.debt) > 0,
			disabled: false,
			title: "Nuevo pago",
			icon: faEuro,
			selected: display === "newCharge",
			onClick: () => {
				setValue("returned", 0)
				setDisplay("newCharge")
			},
		},
		newPayback: {
			condition: Number(originalValues?.final_price) - Number(originalValues?.debt) > 0,
			disabled: false,
			title: "Nueva devolución",
			icon: faCreativeCommonsNcEu,
			selected: display === "newPayback",
			onClick: () => {
				setValue("paid", 0)
				setDisplay("newPayback")
			},
		},
	};

	const onClose = () => {
		refresh();
		setDisplay("newCharge");
		resetForm();
	}

	return (
		<Popup
			onClose={onClosePopup(onClose)}
			onOpen={onOpenPopup()}
			ref={ref}
			modal
			position="bottom center"
			lockScroll
			nested
			closeOnDocumentClick={false}
			trigger={trigger}
			contentStyle={{ margin: "20px auto", width: windowWidth > 768 ? "70%" : "90%", maxHeight: "95%" }}
		>
			<div
				className="p-5 bg-base-100 rounded-lg scrollbar-thumb-rounded-full
            		scrollbar-track-rounded-full scrollbar-thin scrollbar-thumb-base-300 
            		scrollbar-track-base-100 overflow-x-hidden"
				style={{ width: "100%", maxHeight: "100%", overflowY: "auto" }}
			>
				<div className={"p-5 bg-base-100 rounded-lg"}>
					<h3 className="text-2xl text-primary">Datos del albarán</h3>
					<form className={"my-3 grid grid-cols-1 md:grid-cols-2 gap-3"}>
						<CustomForm
							formConfig={formConfig}
							errors={errors}
							register={register}
						/>
					</form>

					<div className="flex items-center justify-between mt-5">
						<h3 className="text-xl text-primary">{optionsConfig[display]?.title}</h3>

						{/* Displayed selector */}
						{Object.values(optionsConfig).filter(o => o.condition).length > 1 && <OptionSelector options={optionsConfig} />}
					</div>

					<div className="my-3">
						{
							<PayWithCashRegister
								version={display === "newPayback" ? "return" : "pay"}
								register={register}
								errors={errors}
								setValue={setValue}
								watch={watch}
								originalValues={originalValues}
							/>
						}
					</div>

					<div className="mt-5 flex space-x-2 sm:space-x-4">
						{<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmit(
								postCharge,
								clinicId,
								originalValues?.cash_register_id,
								closeTooltip
							)}
							disabled={isSubmitting}
						>
							Confirmar
						</button>}

						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmitWithoutValidation(closeTooltip)}
							disabled={isSubmitting}
						>
							Cancelar
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default RegisterChargePopup;
