import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faDownload } from "@fortawesome/free-solid-svg-icons";

import PaginatorComponent from "../../general/auxiliar/PaginatorComponent";
import ModalConfirm from "../../general/auxiliar/modal/ModalConfirm";
import UploadDocumentPopup from "./UploadDocumentPopup";
import usePaginationFilterEffects from "../../../auxiliar/customHooks/usePaginationFilterEffects";
import { getAllDocuments, deleteDocument } from "../../../actions/document";
import {
	handleSubmit,
	handleSubmitWithDispatch,
} from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";

const OverviewDocuments = () => {
	const modalId = "delete-document-modal";

	const dispatch = useDispatch();
	const { patient_id: patientId } = useParams();
	const { id: activeClinicId } = useSelector((state) => state.activeClinic);

	const [documents, setDocuments] = useState({ waiting: true });
	const [filter, setFilter] = useState({
		per_page: 3,
		page: 1,
		clinic_id: activeClinicId,
		patient_id: patientId,
		order: "upload_date",
	});

	const [refreshBool, setRefreshBool] = useState(false);
	const refresh = () => {
		if (
			filter.page > 1 &&
			documents?.order?.length === 1 &&
			!documents?.pagination?.has_next
		) {
			setFilter({ ...filter, page: filter.page - 1 });
		}
		setRefreshBool(!refreshBool);
	};

	const disabled = usePaginationFilterEffects(
		filter,
		undefined,
		getAllDocuments,
		setDocuments,
		setFilter,
		refreshBool
	);

	const [deleteId, setDeleteId] = useState();
	const deleteDoc = (id) => {
		setDeleteId(id);
		document.getElementById(modalId)?.showModal();
	};

	return (
		<>
			{documents.waiting ? (
				<div className="flex items-center justify-center">
					<span className="loading loading-dots loading-lg" />
				</div>
			) : !isDefined(documents.order) ||
				!isDefined(documents.content) ||
				documents.order.length <= 0 ? (
				<div className="flex h-40 w-full flex-col items-center justify-center gap-y-2 rounded-md  p-4 border-4 border-dotted border-base-300 bg-base-200">
					<p className="text-sm">Empieza a almacenar los documentos del paciente aquí</p>
					<UploadDocumentPopup refresh={refresh} />
				</div>
			) : (
				<div className="overflow-x-auto overflow-y-hidden">
					<table className="table">
						<thead>
							<tr>
								<th>Documento</th>
								<th className="hidden md:table-cell">Fecha</th>
								<th />
								<th>
									<div className="flex gap-3 justify-end">
										<UploadDocumentPopup refresh={refresh} />
										<PaginatorComponent
											pagination={documents.pagination}
											setFilter={setFilter}
											filter={filter}
											disabled={disabled}
										/>
									</div>
								</th>
							</tr>
						</thead>

						<tbody>
							{documents.order.map((id) => {
								const doc = documents.content && documents.content[id];

								return (
									<tr key={id}>
										<td>{doc.file_name}</td>
										<td className="hidden md:table-cell">{doc.upload_date}</td>
										<td>
											<a href={doc.download_url} download={doc.file_name}>
												<button type="button" className="btn btn-primary btn-sm">
													<FontAwesomeIcon icon={faDownload} alt="Descargar" />
												</button>
											</a>
										</td>
										<td className="text-center">
											<button
												type="button"
												className="btn btn-error btn-sm"
												onClick={handleSubmit(deleteDoc, id)}
											>
												<FontAwesomeIcon icon={faTrash} alt="Eliminar" />
											</button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			)}

			<ModalConfirm
				id={modalId}
				title="¿Estás seguro de que quieres eliminar el documento?"
				text="No podremos recuperar la información más adelante."
				onConfirm={handleSubmitWithDispatch(
					dispatch,
					deleteDocument,
					deleteId,
					modalId,
					refresh
				)}
			/>
		</>
	);
};

export default OverviewDocuments;
