import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Collapse from "../../../general/auxiliar/collapse/Collapse";
import PaginatorComponent from "../../../general/auxiliar/PaginatorComponent";
import OptionSelector from "../../../general/auxiliar/OptionSelector";
import CustomForm from "../../../general/form/CustomForm";
import usePaginationFilterEffects from "../../../../auxiliar/customHooks/usePaginationFilterEffects";
import useFormWithDisable from "../../../../auxiliar/customHooks/useFormWithDisable";
import { getAllVouchers } from "../../../../actions/voucher";
import { handleSubmit } from "../../../../auxiliar/auxFunctions";
import { isDefined } from "../../../../auxiliar/formatValidators";

const OverviewVouchers = ({ options }) => {
	const navigate = useNavigate();

	const { register, watch, errors } = useFormWithDisable();

	const { id: clinicId } = useSelector((state) => state.activeClinic);
	const [isCollapseOpen, setIsCollapseOpen] = useState(false);
	const [vouchers, setVouchers] = useState({ waiting: true });
	const [filter, setFilter] = useState({
		clinic_id: clinicId,
		order: "name",
		per_page: 10,
		page: 1,
	});

	const groupIndiv = watch("group_indiv");
	const sessionType = watch("session_type_id");
	const groupSessionType = watch("group_session_type_id");

	const writtenFilter = {
		name: watch("name") ?? "",
		...(groupIndiv && groupIndiv !== "select" ? { group_indiv: groupIndiv } : {}),
		...(groupIndiv === "individual" && sessionType && sessionType !== "select" ?
			{ session_type_id: sessionType } :
			{}
		),
		...(groupIndiv === "group" && groupSessionType && groupSessionType !== "select" ?
			{ group_session_type_id: groupSessionType } :
			{}
		),
	};

	const disabled = usePaginationFilterEffects(
		filter,
		isCollapseOpen ? writtenFilter : undefined,
		getAllVouchers,
		setVouchers,
		setFilter
	);

	const formConfig = {
		name: {
			condition: isCollapseOpen,
			disabled: false,
			type: "text",
			label: "Nombre",
			placeholder: "Nombre",
			name: "name",
		},
		group_indiv: {
			condition: true,
			disabled: false,
			type: "selector",
			name: "group_indiv",
			label: "Sesiones o clases *",
			options: [
				{ key: "select", label: "Sesiones y clases" },
				{ key: "individual", label: "Sesiones individuales" },
				{ key: "group", label: "Clases" },
			],
			defaultValue: "select",
			validators: { required: "El tipo de bono es obligatorio" },
		},
		session_type_id: {
			condition: isCollapseOpen && groupIndiv === "individual",
			disabled: false,
			type: "session_type_selector",
			label: "Tipo de sesión",
			name: "session_type_id",
		},
		group_session_type_id: {
			condition: isCollapseOpen && groupIndiv === "group",
			disabled: false,
			type: "group_session_type_selector",
			label: "Tipo de clase",
			name: "group_session_type_id",
		},
	};

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<div className="relative">
				<div className="absolute right-0 top-0 z-10">
					<OptionSelector options={options} />
				</div>
				<Collapse
					isMainTitle={true}
					title="Bonos"
					faModality="filter"
					onStateChange={setIsCollapseOpen}
				>
					<form className="mt-3">
						<div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
							<CustomForm
								formConfig={formConfig}
								errors={errors}
								register={register}
							/>
						</div>
					</form>
				</Collapse>
			</div>

			<div className="sm:container p-3 mx-auto my-2">
				{vouchers.waiting ? (
					<div className="flex items-center justify-center">
						<span className="loading loading-dots loading-lg" />
					</div>
				) : !isDefined(vouchers.order) ||
					!isDefined(vouchers.content) ||
					vouchers.order.length <= 0 ? (
					<p className="lg:text-lg my-3">
						No hay tipos de bono registrados, añade el primero haciendo click en
						el botón de abajo.
					</p>
				) : (
					<div className="overflow-x-auto overflow-y-hidden">
						<table className="table">
							<thead>
								<tr>
									<th>Nombre</th>
									<th className="hidden lg:table-cell">Tipo</th>
									<th className="hidden sm:table-cell">Sesiones</th>
									<th className="hidden sm:table-cell">Precio</th>
									<th className="hidden md:table-cell">Validez</th>
									<th />
								</tr>
							</thead>

							<tbody>
								{vouchers.order.map((id) => {
									const voucherType = vouchers.content && vouchers.content[id];

									return (
										<tr key={id}>
											<td className="max-w-xs">{voucherType.name}</td>
											<td className="hidden lg:table-cell max-w-xs">
												{voucherType.type}
											</td>
											<td className="hidden sm:table-cell">
												{voucherType.total_sessions ? voucherType.total_sessions : "Ilimitadas"}
											</td>
											<td className="hidden sm:table-cell">
												{Number(voucherType.price).toFixed(2)}€
											</td>
											<td className="hidden md:table-cell">
												{voucherType.validity_periods} {voucherType.validity_period_unit === "m" ? "meses" : "semanas"}
											</td>
											<td className="text-center">
												<button
													type="button"
													className="btn btn-primary btn-sm"
													onClick={handleSubmit(
														navigate,
														`/services/voucher/${id}`
													)}
												>
													Detalles
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}

				<form className="mt-5 flex justify-between">
					<div className="flex space-x-2 sm:space-x-4">
						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmit(navigate, "/services/voucher/register")}
						>
							Nuevo tipo de bono
						</button>
					</div>

					<PaginatorComponent
						pagination={vouchers.pagination}
						setFilter={setFilter}
						filter={filter}
						disabled={disabled}
					/>
				</form>
			</div>
		</div>
	);
};

export default OverviewVouchers;
