import axios from "axios";
import { pick } from "lodash";

import {
	dispatchActiveClinicClean,
	dispatchActiveClinicSave,
} from "../store/reducers/activeClinicReducer";
import { dispatchLogin } from "../store/reducers/authReducer";

export const getAllClinics = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/clinics/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getClinic = (id) => {
	return async (...args) => {
		const { navigate } = args[2];

		if (isNaN(id)) {
			navigate(`/unknown?entity=clinic&id=${id}`);
			return;
		}

		return axios
			.get(`/api/managers/clinics/${id}`)
			.then((response) => {
				return response.data.content;
			})
			.catch((error) => {
				if ([403, 404].includes(error?.response?.status)) navigate(`/unknown?entity=clinic&id=${id}`);
			});
	};
};

export const getPublicClinic = (params) => {
	return async (...args) => {
		const { navigate } = args[2];

		return axios
			.get(`/api/anonymous/clinic`, { params: params })
			.then((response) => {
				const data = response.data.content;

				return {
					...data.clinic_data,
					schedule: data.schedule,
					logo: data.logo_url
				}
			})
			.catch((error) => {
				if ([403, 404].includes(error?.response?.status)) navigate(`/unknown?entity=publicClinic&id=${params.clinic_id}`);
			});
	};
};

export const postClinic = (data, managerId) => {
	return async (...args) => {
		const dispatch = args[0];
		const { navigate } = args[2];
		const { add_schedule, ...reqData } = data

		return axios
			.post("/api/managers/clinics/", { ...reqData, manager_id: managerId })
			.then((response) => {
				const clinics = response.data.content.clinics;
				const clinicId = Object.keys(clinics).find(
					(key) => clinics[key].name === data.name
				);
				const clinic = clinics[clinicId];
				navigate(`/clinic/${clinicId}`);
				dispatchActiveClinicSave(
					dispatch,
					clinicId,
					clinic.name,
					clinic.status
				);
				dispatchLogin(dispatch, response.data.content);
			})
			.catch(() => { });
	};
};

export const putClinic = (data, id, disableEdit) => {
	const reqData = pick(data, [
		"address",
        "town",
        "name",
        "email",
        "mobile_phone",
        "landline_phone",
        "invoice_code",
        "invoice_number",
        "cif",
        "task_generation",
        "online_booking",
		"password",
		"schedule",
        "future_debt"
	])
	return async () => {
		return axios
			.put(`/api/managers/clinics/${id}`, { new_info: reqData })
			.then(() => {
				disableEdit();
			})
			.catch(() => { });
	};
};

export const deleteClinic = (id) => {
	return async (...args) => {
		const dispatch = args[0];
		const { navigate } = args[2];

		return axios
			.delete(`/api/managers/clinics/${id}`)
			.then((response) => {
				dispatchLogin(dispatch, response.data.content);
				dispatchActiveClinicClean(dispatch);
				navigate("/clinic");
			})
			.catch(() => { });
	};
};

export const deleteClinicPassword = (id, close) => {
	return async () => {
		return axios
			.delete(`/api/managers/clinics/${id}/remove_password`)
			.then(() => {
				close();
			})
			.catch(() => { });
	};
};

export const putClinicPassword = (data, id, close) => {
	const reqData = {
		password: data.password, // only necessary for the password change
		new_info: {
			password: data.new_info_password,
			password_rep: data.new_info_password_rep,
		},
	};

	return async () => {
		return axios
			.put(`/api/managers/clinics/${id}`, reqData)
			.then(() => {
				close();
			})
			.catch(() => { });
	};
};

export const postLogo = (data, clinicId, close) => {
	return async () => {
		const formData = new FormData();
		formData.append("clinic_id", clinicId);
		formData.append("logo", data.logo[0]);

		return axios
			.post(`/api/managers/clinics/add_logo/${clinicId}`, formData, {
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then(() => {
				close();
			})
			.catch(() => { });
	};
};

export const deleteLogo = (clinicId, close) => {
	return async () => {
		return axios
			.delete(`/api/managers/clinics/remove_logo/${clinicId}`)
			.then(() => {
				close();
			})
			.catch(() => { });
	};
};

export const putClinicSchedule = (data, clinicId, close) => {
	return async () => {
		return axios
			.put(`/api/managers/clinics/${clinicId}/update_schedule`, { new_info: data })
			.then(() => {
				close();
			})
			.catch(() => { });
	};
};

// Payment Gateway
export const getClinicPaymentGatewayParams = (params) => {
	const path = process.env.NODE_ENV !== "production" ?
		"https://sis-t.redsys.es:25443/sis/realizarPago" :
		"https://sis.redsys.es/sis/realizarPago";

	return async () => {
		return axios
			.get(`/api/managers/payment_gateway/generate_request`, { params: params })
			.then((response) => {
				return {
					params: response.data.content,
					path: path
				}
			})
			.catch(() => { });
	};
};