import { useMatch, Link } from "react-router-dom";

import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import { getDynamicLink } from "../../../auxiliar/auxFunctions";

const SmallFooter = () => {
	const reservation = useMatch("/reservation/*");
	const params = useQueryParams();

	return (
		<footer className="footer footer-center py-3 bg-base-100 text-base-content border-t border-primary">
			<div>
				<nav className="grid grid-flow-col gap-4">
					<Link
						className="link link-hover"
						to={getDynamicLink(
							reservation ? "/reservation" : "",
							"about-us",
							params
						)}
					>
						Nosotros
					</Link>
					<Link
						className="link link-hover"
						to="mailto:soporte@mifisioagenda.com"
					>
						Contacto
					</Link>
					<Link
						className="link link-hover"
						to={getDynamicLink(
							reservation ? "/reservation" : "",
							"privacy-policy",
							params
						)}
					>
						Privacidad
					</Link>
				</nav>

				<aside>
					<p>MFISIO SCHEDULA SL - © {new Date().getFullYear()}</p>
				</aside>
			</div>
		</footer>
	);
};

export default SmallFooter;
