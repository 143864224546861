import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { dispatchCookiesAccept } from "../store/reducers/cookiesReducer";

const CookiesConfirm = () => {
	const dispatch = useDispatch();
	const accepted = useSelector((state) => state.cookiesReducer.accepted);

	if (accepted) {
		return null; // Don't render anything if the cookies are already accepted
	}

	return (
		<div className="z-60 opacity-100 pointer-events-none absolute bottom-10 left-1/2 transform -translate-x-1/2 overflow-hidden w-[90vw]">
			<div key={"cookies-confirm"} className="toast-bottom toast-center">
				<div
					role="alert"
					className="alert bg-base-300 pointer-events-auto border border-secondary"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						className="stroke-secondary shrink-0 w-6 h-6"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
						></path>
					</svg>
					<span className="text-center">
						Usamos cookies técnicas para asegurarnos de que todo funcione
						correctamente. Al seguir navegando, aceptas nuestra{" "}
						<Link className="link" to="/cookies">
							política de cookies
						</Link>
						. Le informaremos si se produce algún cambio.
					</span>
					<div>
						<button
							className="btn btn-sm btn-secondary"
							onClick={() => dispatchCookiesAccept(dispatch)}
						>
							Aceptar
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CookiesConfirm;
