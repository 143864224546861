import { Link } from "react-router-dom";

import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import ThemeSelector from "./ThemeSelector";
import { getDynamicLink } from "../../../auxiliar/auxFunctions";

const NavbarReservation = () => {
	const params = useQueryParams();

	return (
		<div className="navbar bg-base-300">
			<div className="navbar-start">
				<div className="dropdown">
					<div
						tabIndex={0}
						role="button"
						className="btn btn-ghost lg:hidden p-3"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-5 w-5"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M4 6h16M4 12h8m-8 6h16"
							/>
						</svg>
					</div>
					<ul
						tabIndex={0}
						className={`menu menu-sm dropdown-content mt-3 z-40 p-2 shadow-sm bg-base-300 rounded-box w-52`}
					>
						<li>
							<Link to={getDynamicLink("/reservation", "agenda", params)}>Agenda</Link>
						</li>
						{params.has("session") && (
							<li>
								<Link to={getDynamicLink("/reservation", "session", params)}>
									Reserva
								</Link>
							</li>
						)}
						<li>
							<Link to={getDynamicLink("/reservation", "clinic", params)}>
								Clínica
							</Link>
						</li>
						<li>
							<Link to={getDynamicLink("/reservation", "info", params)}>
								Información
							</Link>
						</li>
						<li>
							<Link to="/login">Soy un Fisio</Link>
						</li>
					</ul>
				</div>

				<Link className="btn btn-ghost text-xl hidden lg:flex" to={getDynamicLink("/reservation", "agenda", params)}>
					MiFisio
				</Link>
			</div>
			<div className="navbar-center">
				<ul className="menu menu-horizontal px-1 hidden lg:flex">
					<li>
						<Link to={getDynamicLink("/reservation", "agenda", params)}>Agenda</Link>
					</li>
					{params.has("session") && (
						<li>
							<Link to={getDynamicLink("/reservation", "session", params)}>
								Reserva
							</Link>
						</li>
					)}
					<li>
						<Link to={getDynamicLink("/reservation", "clinic", params)}>Clínica</Link>
					</li>
					<li>
						<Link to={getDynamicLink("/reservation", "info", params)}>
							Información
						</Link>
					</li>
					<li>
						<Link to="/login">Soy un Fisio</Link>
					</li>
				</ul>

				<Link className="btn btn-ghost text-xl flex lg:hidden" to={getDynamicLink("/reservation", "agenda", params)}>
					MiFisio
				</Link>
			</div>
			<div className="navbar-end">
				<ThemeSelector />
			</div>
		</div>
	);
};

export default NavbarReservation;
