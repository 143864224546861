import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

import { isDefined } from "../../../auxiliar/formatValidators";
import { handleSubmit } from "../../../auxiliar/auxFunctions";

const WhatsAppReminder = ({ link }) => {

	const onWhatsAppClick = () => {
		// Build the WhatsApp URL
		const appUrl = link.replace("https://web.whatsapp.com/", "whatsapp://");
		
		// Set a flag before trying to open the app
		const beforeUnload = Date.now();
		localStorage.setItem('whatsappAttempt', beforeUnload);

		// Try to open WhatsApp app
		window.location.href = appUrl;

		// Only trigger this if we're still on the page
		window.onblur = () => {
			localStorage.removeItem('whatsappAttempt');
			window.onblur = null;
		};

		// If we're still here after 100ms, the app didn't open
		setTimeout(() => {
			const attemptTime = localStorage.getItem('whatsappAttempt');
			if (attemptTime && attemptTime == beforeUnload) {
				localStorage.removeItem('whatsappAttempt');
				window.onblur = null;
				window.open(link, "_blank");
			}
		}, 100);
	}

	if (!isDefined(link)) return null;

	return (
		<button
			onClick={handleSubmit(onWhatsAppClick)}
			className="mx-1 cursor-pointer"
		>
			<FontAwesomeIcon icon={faWhatsapp} className="text-secondary text-lg" />
		</button>
	);
};

export default WhatsAppReminder;