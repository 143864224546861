import { useEffect } from "react";
import { format } from 'date-fns';

import CustomForm from "../../../general/form/CustomForm";
import useQueryParams from "../../../../auxiliar/customHooks/useQueryParams";
import { isDefined, checkMailFormat, checkPhoneNumberFormat } from "../../../../auxiliar/formatValidators";

const RegisterUnconfirmedSession = ({
	eventData,
	register,
	errors,
	watch,
	setValue,
	onlyDisplay = false,
}) => {
	const params = useQueryParams();

	const startDatetime = watch("start_datetime");
	const sessionReason = watch("session_reason");
	const sessionTypeData = watch("session_type_data");

	const formConfig = {
		patient_title: {
			condition: true,
			type: "title",
			text: "Datos del paciente",
			classname: "text-primary mt-3 text-lg col-span-1 md:col-span-2",
		},
		patient_name: {
			condition: true,
			disabled: onlyDisplay,
			defaultValue: eventData.patient_name,
			type: "text",
			label: "Nombre *",
			placeholder: "Nombre",
			name: "patient_name" + (onlyDisplay ? "-tmp" : ""),
			validators: onlyDisplay ? {} : {
				required: "El nombre es obligatorio"
			},
		},
		patient_surnames: {
			condition: true,
			disabled: onlyDisplay,
			defaultValue: eventData.patient_surnames,
			type: "text",
			label: "Apellidos *",
			placeholder: "Apellidos",
			name: "patient_surnames" + (onlyDisplay ? "-tmp" : ""),
			validators: onlyDisplay ? {} : {
				required: "El nombre es obligatorio"
			},
		},
		patient_email: {
			condition: true,
			disabled: onlyDisplay,
			defaultValue: eventData.patient_email,
			type: "text",
			label: "Correo Electrónico *",
			placeholder: "paciente@email.com",
			name: "patient_email" + (onlyDisplay ? "-tmp" : ""),
			validators: onlyDisplay ? {} : {
				required: "El correo es obligatorio",
				validate: checkMailFormat,
			},
		},
		patient_phone_number: {
			condition: true,
			disabled: onlyDisplay,
			defaultValue: eventData.patient_phone_number,
			type: "text",
			label: "Número de teléfono *",
			placeholder: "+34 XXX XXX XXX",
			name: "patient_phone_number" + (onlyDisplay ? "-tmp" : ""),
			validators: onlyDisplay ? {} : {
				required: "El número de teléfono es obligatorio",
				validate: checkPhoneNumberFormat,
			},
		},
		session_title: {
			condition: true,
			type: "title",
			text: "Datos de la sesión",
			classname: "text-primary mt-3 text-lg col-span-1 md:col-span-2",
		},
		// Original edit fields
		employee_id: {
			condition: !onlyDisplay,
			disabled: false,
			defaultValue: eventData.employee_id === "all" ? "select" : eventData.employee_id,
			type: "physio_selector",
			label: "Fisioterapeuta *",
			name: "employee_id",
			filter: {
				active: true,
			}
		},
		session_type_data: {
			condition: !onlyDisplay,
			disabled: false,
			defaultValue: eventData.session_type_id,
			type: "session_type_selector_with_metadata",
			name: "session_type_data",
			label: "Tipo de sesión *",
		},
		// Only display fields     
		employee_name: {
			condition: onlyDisplay,
			disabled: true,
			defaultValue: eventData.employee_name,
			type: "text",
			label: "Fisioterapeuta *",
			name: "employee_name-tmp",
		},
		session_type_name: {
			condition: onlyDisplay,
			disabled: true,
			defaultValue: eventData.session_type_name,
			type: "text",
			name: "session_type_name-tmp",
			label: "Tipo de sesión *",
		},
		// Other fields present in both edit and display
		start_datetime: {
			condition: true,
			disabled: onlyDisplay,
			defaultValue: eventData.start_datetime,
			type: "datetime-local",
			label: "Fecha y hora de inicio *",
			name: "start_datetime" + (onlyDisplay ? "-tmp" : ""),
			validators: onlyDisplay ? {} : {
				required: "Elegir una fecha y hora de inicio es obligatorio",
				validate: (v) => isDefined(v) || "Elegir una fecha y hora de inicio es obligatorio",
			},
		},
		finish_datetime: {
			condition: true,
			disabled: onlyDisplay,
			defaultValue: eventData.finish_datetime,
			type: "datetime-local",
			label: "Fecha y hora de fin *",
			name: "finish_datetime" + (onlyDisplay ? "-tmp" : ""),
			validators: onlyDisplay ? {} : {
				required: "Elegir una fecha y hora de fin es obligatorio",
				validate: (v) => {
					if (!isDefined(v)) return "Elegir una fecha y hora de fin es obligatorio"
					if (new Date(v).getTime() <= new Date(startDatetime).getTime()) return "La fecha de fin debe ser posterior a la de inicio"
				}
			},
		},
		session_reason: {
			condition: true,
			disabled: onlyDisplay,
			defaultValue: eventData.session_reason,
			type: "textarea",
			label: `Motivo de la sesión (${sessionReason?.length || 0}/10000)`,
			placeholder: onlyDisplay ? "" : "...",
			name: "session_reason" + (onlyDisplay ? "-tmp" : ""),
			validators: onlyDisplay ? {} : {
				maxLength: {
					value: 10000,
					message: "El comentario es demasiado largo",
				},
			},
		},
	};

	// Initially sets the start and finish date and hour based on the event data
	useEffect(() => {
		if (onlyDisplay) return;

		setValue("clinic_id", params.get("clinic"))

		if (eventData.startAt)
			setValue("start_datetime", format(
				new Date(eventData.startAt),
				"yyyy-MM-dd'T'HH:mm"
			).toString())
		if (eventData.endAt)
			setValue("finish_datetime", format(
				new Date(eventData.endAt),
				"yyyy-MM-dd'T'HH:mm"
			).toString())
	}, [eventData]);

	// Sets the finish date and hour based on the session type duration
	useEffect(() => {
		if (onlyDisplay) return;

		const sessionTypeId = sessionTypeData?.split("-")[0];
		if (isDefined(sessionTypeId)) {
			setValue("session_type_id", sessionTypeId)
		}

		const sessionDuration = sessionTypeData?.split("-")[1] || 60;

		let startingTime = new Date(startDatetime).getTime();
		if (isNaN(startingTime)) startingTime = new Date().getTime();

		setValue("finish_datetime", format(
			new Date(startingTime + sessionDuration * 60000),
			"yyyy-MM-dd'T'HH:mm"
		).toString())
	}, [sessionTypeData, startDatetime]);

	return (
		<form className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
			<CustomForm
				formConfig={formConfig}
				errors={errors}
				register={register}
			/>
		</form>
	)
}

export default RegisterUnconfirmedSession;