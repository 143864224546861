import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const FormCheckbox = ({ customKey, register, config, errors }) => {
	return (
		<div key={customKey} className={`form-control flex flex-col ${config.classname}`}>
			<label
				className="label-text flex text-left cursor-pointer items-center"
				onChange={config.onChange ? config.onChange : () => { }}
			>
				<input
					className={`checkbox disabled:bg-disabled disabled:text-disabled-content 
						${errors ?
							"checkbox-error disabled:checkbox-error" :
							"checkbox-primary"
						}`
					}
					data-tip={errors?.message}
					disabled={config.disabled}
					type={config.type}
					name={config.name}
					id={config.name}
					defaultChecked={config.defaultValue}
					{...register(config.name, config.validators ? config.validators : {
						required: false,
						validate: false,
					})}
				/>
				<span className="px-2">{config.label}</span>{" "}
				{config.info && (
					<div className="tooltip" data-tip={config.info}>
						<FontAwesomeIcon icon={faCircleInfo} className="text-primary" />
					</div>
				)}
			</label>
		</div>
	);
};

export default FormCheckbox;
