import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGroup, faPlus, faChevronDown, faCircleInfo } from "@fortawesome/free-solid-svg-icons";

import CustomForm from "./CustomForm";
import OptionSelector from "../auxiliar/OptionSelector";
import PaginatorComponent from "../auxiliar/PaginatorComponent";
import WhatsAppReminder from "../auxiliar/WhatsAppReminder";
import { getAllPatients } from "../../../actions/patient";
import { handleSubmit, debounce, fetchData } from "../../../auxiliar/auxFunctions";
import {
    isDefined,
    checkMailFormat,
    checkPhoneNumberFormat,
} from "../../../auxiliar/formatValidators";


const CustomPatientsHandler = ({
    register,
    errors,
    watch,
    setValue,
    filter,
    filterAdded,
    maxParticipants,
    disabled,
    operation,
    onExistingClick,
    onAddNewClick,
    onClientSelect,
    onClientRegister,
    onDeletePatient,
    onPaymentClick,
    otherOptionsInHeader,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const writtenFilter = {
        operation: "displayAssisting",
        name: watch("patient_name") || "",
        surnames: watch("patient_surnames") || "",
        email: watch("patient_email") || "",
        phone_number: watch("patient_phone_number") || "",
        patient_id: watch("patient_id"), // necessary for internal state
    }

    const [debouncedFilter, setDebouncedFilter] = useState(writtenFilter);
    const [patients, setPatients] = useState({ waiting: true });
    const [alreadyAdded, setAlreadyAdded] = useState(0)
    const [assistingPatientsPagination, setAssistingPatientsPagination] = useState({
        per_page: 5,
        page: 1,
        order: "name",
    })

    const processChange = useMemo(() => debounce((newFilter) => setDebouncedFilter(newFilter), 300), []);

    const onAddPatientClick = (patientId) => {
        if (patientId === "new") {
            setPatients({ order: [], content: {}, waiting: false })
        } else if (debouncedFilter.operation !== "addPatient") {
            setPatients({ waiting: true })
        }
        setValue("patient_id", patientId);

        if (onAddNewClick) {
            onAddNewClick();
        }
    };

    const onAlreadyAddedClick = () => {
        if (debouncedFilter.operation !== "displayAssisting") {
            setPatients({ waiting: true })
        }
        setValue("patient_id", undefined);

        if (onExistingClick) {
            onExistingClick();
        }
    };

    const setPatientsAux = (patients) => {
        setPatients(patients)
        setAlreadyAdded(patients?.order?.length || 0)
    }

    // Selector Configuration
    const selectorOptions = {
        addedPatients: {
            condition: true,
            disabled: disabled,
            selected: operation.operation === "displayAssisting",
            title: isDefined(filterAdded?.group_session_id) ? "Asistentes" : "Autorizados",
            icon: faUserGroup,
            onClick: handleSubmit(onAlreadyAddedClick),
        },
        newPatients: {
            condition: true,
            disabled: disabled,
            title: "Añadir Paciente",
            icon: faPlus,
            selected: operation.operation === "addPatient",
            multiOption: {
                existing_patient: {
                    title: "Existente",
                    condition: !isDefined(filter?.patient_id),
                    disabled: disabled,
                    selected: operation.operation === "addPatient" && debouncedFilter.patient_id !== "new",
                    onClick: handleSubmit(onAddPatientClick, undefined),
                },
                new_patient: {
                    title: "Nuevo",
                    condition: !isDefined(filter?.patient_id),
                    disabled: disabled,
                    selected: operation.operation === "addPatient" && debouncedFilter.patient_id === "new",
                    onClick: handleSubmit(onAddPatientClick, "new"),
                },
                register: {
                    title: "Registrar",
                    condition: !isDefined(filter?.patient_id) && isDefined(onClientRegister),
                    disabled: disabled || debouncedFilter.patient_id !== "new" || operation.operation !== "addPatient",
                    selected: undefined,
                    onClick: handleSubmit(onClientRegister, { patient_id: "new" }),
                },
            }
        },
        ...otherOptionsInHeader
    };

    // Form Configuration
    const formConfig = {
        patient_name: {
            condition: true,
            disabled: (isDefined(debouncedFilter.patient_id) && debouncedFilter.patient_id !== "new") || isDefined(filter.patient_id),
            type: "text",
            label: `Nombre${debouncedFilter.patient_id === "new" ? " *" : ""}`,
            placeholder: "Nombre",
            name: "patient_name",
            validators: {
                validate: (v) => {
                    if (debouncedFilter.patient_id === "new") {
                        if (v.length < 3) return "El nombre es obligatorio"
                        return true
                    }
                    return true
                },
            }
        },
        patient_surnames: {
            condition: true,
            disabled: (isDefined(debouncedFilter.patient_id) && debouncedFilter.patient_id !== "new") || isDefined(filter.patient_id),
            type: "text",
            label: `Apellidos${debouncedFilter.patient_id === "new" ? " *" : ""}`,
            placeholder: "Apellidos",
            name: "patient_surnames",
            validators: {
                validate: (v) => {
                    if (debouncedFilter.patient_id === "new") {
                        if (v.length < 3) return "Los apellidos son obligatorios";
                        return true
                    }
                    return true
                }
            },
        },
        patient_email: {
            condition: true,
            disabled: (isDefined(debouncedFilter.patient_id) && debouncedFilter.patient_id !== "new") || isDefined(filter.patient_id),
            type: "text",
            label: "Correo Electrónico",
            placeholder: "paciente@email.com",
            name: "patient_email",
            validators: {
                validate: (v) => {
                    if (debouncedFilter.patient_id === "new") {
                        return !isDefined(v) || checkMailFormat(v);
                    } else {
                        return true;
                    }
                }
            }
        },
        patient_phone_number: {
            condition: true,
            disabled: (isDefined(debouncedFilter.patient_id) && debouncedFilter.patient_id !== "new") || isDefined(filter.patient_id),
            type: "text",
            label: "Número de teléfono",
            placeholder: "+34 XXX XXX XXX",
            name: "patient_phone_number",
            validators: {
                validate: (v) => {
                    if (debouncedFilter.patient_id === "new") {
                        return !isDefined(v) || checkPhoneNumberFormat(v);
                    } else {
                        return true;
                    }
                }
            }
        },
    };

    useEffect(() => {
        // If the class changes, the loading visual is displayed
        if (filterAdded.group_session_id !== debouncedFilter.group_session_id) {
            setPatients({ waiting: true });
        }

        const newFilter = { ...filterAdded, ...writtenFilter, operation: operation.operation, update: operation.update };
        if (JSON.stringify(debouncedFilter) !== JSON.stringify(newFilter)) {
            processChange(newFilter);
        }
    }, [JSON.stringify(writtenFilter), filterAdded, operation.operation, operation.update]);

    useEffect(() => {
        if (!isDefined(debouncedFilter.clinic_id)) return;

        if (debouncedFilter.operation === "displayAssisting") {
            fetchData(
                dispatch,
                getAllPatients,
                filterAdded,
                setPatientsAux
            );
        } else if (debouncedFilter.patient_id !== "new" && debouncedFilter.operation === "addPatient") {
            fetchData(
                dispatch,
                getAllPatients,
                {
                    ...filter,
                    name: debouncedFilter.name,
                    surnames: debouncedFilter.surnames,
                    email: debouncedFilter.email,
                    phone_number: debouncedFilter.phone_number
                },
                setPatients
            );
        }
    }, [debouncedFilter, assistingPatientsPagination, dispatch, setPatients]);

    return (
        <form className={"my-3 mx-auto"}>
            <div className="flex justify-between">
                <h2 className="text-primary text-xl">
                    {["addPatient", "displayAssisting"].includes(operation.operation) && (
                        `${isDefined(filterAdded?.group_session_id) ? "Asistentes" : "Autorizados"} (${alreadyAdded}/${maxParticipants || "∞"})`
                    )}
                    {["pay"].includes(operation.operation) && (<>
                        {operation.metadata.display === "cash_register_return" ? "Devolución" : "Pago"}: {operation.metadata.name || ""}
                        <span className="hidden md:inline">{" "}{operation.metadata.surnames || ""}</span>
                    </>)}
                </h2>

                <div className="flex gap-3 md:justify-end">
                    <OptionSelector options={selectorOptions} />
                </div>
            </div>

            { /* if adding a patient the filter is displayed */}
            {operation.operation === "addPatient" && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3 my-2">
                    <CustomForm
                        formConfig={formConfig}
                        errors={errors}
                        register={register}
                    />
                </div>
            )}

            { /* Patients table if not registering a new patient */}
            {
                (["addPatient", "displayAssisting", "deletePatient"].includes(operation.operation) && debouncedFilter.patient_id !== "new") && (
                    patients.waiting ? (
                        <div className="flex items-center justify-center">
                            <span className="loading loading-dots loading-lg" />
                        </div>
                    ) : !isDefined(patients.order) ||
                        !isDefined(patients.content) ||
                        patients.order.length <= 0 ? (
                        <p className="lg:text-lg my-3">
                            No hay pacientes registrados con estos datos.
                        </p>
                    ) : (
                        <div className="overflow-x-visible overflow-y-visible mt-4">
                            {operation.operation === "addPatient" && <div className="mt-5 flex justify-between">
                                <p className="text-primary my-3">
                                    {patients.pagination.total_items} Pacientes encontrados
                                </p>
                            </div>}

                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>Nombre y Apellidos</th>
                                        <th className="hidden lg:table-cell">Correo</th>
                                        <th className="hidden sm:table-cell">Teléfono</th>
                                        {operation.operation === "displayAssisting" && isDefined(Object.values(patients.content)[0].debt) && (
                                            <th className="hidden md:table-cell">
                                                Deuda clase {isDefined(Object.values(patients.content)[0].patient_debt) && <span className="tooltip" data-tip={
                                                    "Por defecto se muestra la deuda de la clase, pero al pasar el ratón por encima se muestra la total"
                                                }>
                                                    <FontAwesomeIcon icon={faCircleInfo}/>
                                                </span>}
                                            </th>
                                        )}
                                        <th>
                                            {operation.operation === "displayAssisting" && <PaginatorComponent
                                                pagination={patients.pagination}
                                                setFilter={setAssistingPatientsPagination}
                                                filter={assistingPatientsPagination}
                                                disabled={disabled}
                                                handleSubmitWithBlock={handleSubmit}
                                            />}
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {patients.order.map((patientId) => {
                                        const patient = patients.content && patients.content[patientId];
                                        const debt = Number(patient?.debt || 0) > 0 ? Number(patient.debt).toFixed(2) : "Pagado";
                                        const tooltip = Number(patient?.patient_debt || 0) > 0 ?
                                            `La deuda del paciente es de ${Number(patient.patient_debt).toFixed(2)}€` :
                                            "El paciente no tiene deuda";

                                        return (
                                            <tr key={patientId}>
                                                <td>{`${patient.name} ${patient.surnames}`}</td>
                                                <td className="hidden lg:table-cell">{patient.email}</td>
                                                <td className="hidden sm:table-cell">
                                                    <div className="flex">
                                                        <span>{patient.phone_number}</span>
                                                        <WhatsAppReminder link={patient.link} />
                                                    </div>
                                                </td>
                                                {operation.operation === "displayAssisting" && isDefined(patient.debt) && (
                                                    <td className="hidden md:table-cell">
                                                        {isDefined(patient.patient_debt) ?
                                                            <span className="tooltip hover:cursor-help" data-tip={tooltip}>
                                                                {debt}
                                                            </span> :
                                                            debt
                                                        }
                                                    </td>
                                                )}
                                                <td className="text-center">
                                                    {["displayAssisting", "deletePatient"].includes(operation.operation) && (
                                                        <div className="dropdown dropdown-left">
                                                            <div
                                                                tabIndex={0}
                                                                role="button"
                                                                className="btn btn-primary btn-sm"
                                                                disabled={disabled}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={faChevronDown}
                                                                    className="text-base-100"
                                                                />
                                                                <span className="hidden lg:inline">{" "}Opciones</span>
                                                            </div>
                                                            <ul
                                                                tabIndex={0}
                                                                className="dropdown-content z-40 menu p-2 shadow-sm bg-base-300 rounded-box w-52"
                                                            >
                                                                <li
                                                                    onClick={handleSubmit(
                                                                        navigate,
                                                                        `/patient/${patientId}`
                                                                    )}
                                                                    disabled={disabled}
                                                                >
                                                                    <a>Detalles</a>
                                                                </li>
                                                                {onPaymentClick && <li
                                                                    onClick={onPaymentClick(patient)}
                                                                    disabled={disabled}
                                                                >
                                                                    <a>Pago</a>
                                                                </li>}
                                                                <li>
                                                                    <button
                                                                        onClick={handleSubmit(onDeletePatient, {
                                                                            patient_id: patientId,
                                                                            recurrent: patient.recurrent
                                                                        })}
                                                                        className={`disabled:cursor-not-allowed disabled:text-disabled-content`}
                                                                        disabled={disabled || patient.role === "Owner"}
                                                                    >
                                                                        <a>Eliminar</a>
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )}

                                                    {operation.operation === "addPatient" && (
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-sm"
                                                            onClick={handleSubmit(onClientSelect, patient)}
                                                            disabled={disabled}
                                                        >
                                                            Añadir
                                                        </button>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    ))
            }
        </form>
    );
};

export default CustomPatientsHandler;
