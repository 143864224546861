import logo_colegio_madrid from "../../../media/Partners/Logo_colegio_madrid.png";
import logo_colegio_cantabria from "../../../media/Partners/Logo_colegio_cantabria.png";
import logo_colegio_valencia from "../../../media/Partners/Logo_colegio_valencia.png";
import logo_fisiocampus from "../../../media/Partners/Logo_fisiocampus.png";
import logo_fisiogestiona from "../../../media/Partners/Logo_fisiogestiona.png";
import plus_symbol from "../../../media/Partners/green-blue.png";

const Partners = () => {
	return (
		<section className="p-4 bg-base-200 items-center justify-center px-8 md:px-28 py-24">
			<p className="mb-8 text-center text-3xl font-bold text-primary">
				Colaboraciones
			</p>

			<div className="grid grid-cols-1 gap-20 md:grid-cols-2 xl:grid-cols-3 my-3">
				<div className="bg-base-100 shadow-xl rounded-lg p-10 flex flex-col items-center justify-between text-center h-120">
					<img
						src={logo_colegio_madrid}
						alt={"Logo Colegio de Fisioterapeutas de la Comunidad de Madrid"}
						className="max-h-full grow h-32 object-contain"
					/>
					<a className={"link my-5"} href={"https://cfisiomad.org/"}>
						Ilustre Colegio Profesional de Fisioterapeutas de la Comunidad de Madrid
					</a>
				</div>
				<div className="bg-base-100 shadow-xl rounded-lg p-10 flex flex-col items-center justify-between text-center h-120">
					<img
						src={logo_colegio_cantabria}
						alt={"Logo ilustre colegio de fisioterapeutas de Cantabria"}
						className="max-h-full grow h-32 object-contain"
					/>
					<a className={"link my-5"} href={"https://colfisiocant.org/"}>
						Ilustre Colegio Profesional de Fisioterapeutas de Cantabria
					</a>
				</div>
				<div className="bg-base-100 shadow-xl rounded-lg p-10 flex flex-col items-center justify-between text-center h-120">
					<img
						src={logo_colegio_valencia}
						alt={
							"Logo ilustre colegio de fisioterapeutas de la Comunidad Valenciana"
						}
						className="max-h-full grow h-32 object-contain"
					/>
					<a className={"link my-5"} href={"hhttps://colfisiocv.com/"}>
						Ilustre Colegio Profesional de Fisioterapeutas de la Comunidad
						Valenciana
					</a>
				</div>
				<div className="bg-base-100 shadow-xl rounded-lg p-10 flex flex-col items-center justify-between text-center h-120">
					<img
						src={logo_fisiocampus}
						alt={"Logo Fisiocampus"}
						className="max-h-full grow h-32 object-contain"
					/>
					<a className={"link my-5"} href={"https://www.fisiocampus.com/"}>
						Fisiocampus
					</a>
				</div>
				<div className="bg-base-100 shadow-xl rounded-lg p-10 flex flex-col items-center justify-between text-center h-120">
					<img
						src={logo_fisiogestiona}
						alt={"Logo Fisiogestiona"}
						className="max-h-full grow h-32 object-contain"
					/>
					<a className={"link my-5"} href={"https://www.fisiogestiona.es/"}>
						Fisiogestiona
					</a>
				</div>

				<div className="bg-base-100 shadow-xl rounded-lg p-10 flex flex-col items-center justify-between text-center h-120">
					<img src={plus_symbol} alt={"Más"} className="max-h-full grow h-32 object-contain" />
					<a className={"link my-5"} href={"mailto:info@mifisioagenda.com"}>
						¿Quieres ser nuestro colaborador? <br />
						¡Contacta con nosotros!
					</a>
				</div>

				{/* <div className="hidden xl:block">
					Empty div to center the last element
				</div> */}
			</div>
		</section>
	);
};

export default Partners;
