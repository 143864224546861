import axios from "axios";
import { pick } from "lodash";

export const getAllSessions = (params) => {
	return async () => {
		return axios
			.get(`/api/managers/sessions/`, { params: params })
			.then((response) => {
				return response.data;
			})
			.catch(() => { });
	};
};

export const getSession = (sessionId) => {
	return async () => {
		return axios
			.get(`/api/managers/sessions/${sessionId}`)
			.then((response) => {
				return {
					...response.data.content,
					date: response.data.content.start_datetime.substring(0, 10),
				};
			})
			.catch(() => { });
	};
};

export const postSession = (
	sessionData,
	closeTooltip,
) => {
	// build the request data
	const session = pick(sessionData, ["clinic_id", "employee_id", "session_type_id", "comment", "session_reason", "recurrent"]);

	let patient = {};
	if (sessionData.patient_id !== "new") {
		patient = pick(sessionData, ["patient_id"])
	} else {
		patient = {
			patient_id: "new",
			new_patient_info: {
				name: sessionData.patient_name,
				surnames: sessionData.patient_surnames,
				email: sessionData.patient_email,
				phone_number: sessionData.patient_phone_number,
				comments: sessionData.patient_comments,
			}
		}
	}

	let schedule = {}
	if (sessionData.recurrent) {
		schedule = pick(sessionData, ["repeat_monday", "repeat_tuesday", "repeat_wednesday",
			"repeat_thursday", "repeat_friday", "repeat_saturday", "repeat_sunday", "start_hour",
			"finish_hour", "first_date", "last_date"]);
	} else {
		schedule = pick(sessionData, ["start_datetime", "finish_datetime"]);
	}

	const reqData = { ...session, ...schedule, ...patient };

	// make the request
	return async () => {
		return axios
			.post("/api/managers/sessions/", reqData)
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};

export const putSession = (
	data,
	sessionId,
	closeTooltip,
	paymentType
) => {
	// build the request data
	const sessionData = pick(data, ["start_datetime", "finish_datetime", "employee_id",
		"session_type_id", "comment", "session_reason", "attended"]);
	const patientData = pick(data, ["patient_comments"]);
	let paymentData = {}

	if (paymentType === "voucher") {
		paymentData = pick(data, ["acquired_voucher_id"]);
		if (data.acquired_voucher_id === "-1") {
			paymentData.acquired_voucher_id = null;
		}
	} else if (["cashRegister", "payback"].includes(paymentType)) {
		if (Number(data.paid) > 0) {
			paymentData = pick(data, ["initial_price", "discount", "paid", "payment_method"]);
		} else if (Number(data.returned) > 0) {
			paymentData = pick(data, ["initial_price", "discount", "payment_method"]);
			paymentData.paid = -Number(data.returned);
		} else {
			paymentData = pick(data, ["initial_price", "discount"]);
		}
	}

	const reqData = {
		...sessionData,
		...patientData,
		...paymentData
	};

	// make the request
	return async () => {
		return axios
			.put(`/api/managers/sessions/${sessionId}`, { new_info: reqData })
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};

export const deleteSession = (
	sessionId,
	closeTooltip,
	deleteRecurrent = false
) => {
	return async () => {
		return axios
			.delete(
				`/api/managers/sessions/${sessionId}?deleteRecurrent=${deleteRecurrent}`
			)
			.then(() => {
				closeTooltip();
			})
			.catch(() => { });
	};
};

export const getEditSession = (
	sessionId
) => {
	return async () => {
		return axios
			.get(`/api/managers/sessions/edit_session/${sessionId}`)
			.then((response) => {
				return {
					...response.data.content,
					initial_price: Number(response.data.content.initial_price).toFixed(2),
					final_price: Number(response.data.content.final_price).toFixed(2),
					debt: Number(response.data.content.debt).toFixed(2),
				};
			})
			.catch(() => { });
	};
};
