import { useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { getStore } from "../store/store";

import CookiesConfirm from "./CookiesConfirm";
import AuthRoute from "./AuthRoute";
import ProtectedRoute from "./ProtectedRoute";
import AxiosProvider from "./AxiosProvider";
import MessagesDisplay from "../components/general/auxiliar/message/MessagesDisplay";
import Navbar from "../components/general/navbar/Navbar";
import Footer from "../components/general/footer/Footer";

import Login from "../pages/public/session/Login";
import Register from "../pages/public/session/Register";
import ForgottenPassword from "../pages/public/session/ForgottenPassword";
import AboutUs from "../pages/public/AboutUs";
import NotFound from "../pages/public/NotFound";
import PrivacyPolicy from "../pages/public/PrivacyPolicy";
import Legal from "../pages/public/Legal";
import CookiesPolicy from "../pages/public/CookiesPolicy";
import LandingPage from "../pages/public/LandingPage";
import HealthCheck from "../pages/public/HealthCheck";
import Maintenance from "../pages/public/Maintenance";

import ManagerProfile from "../pages/manager/basics/ManagerProfile";
import MainCalendar from "../pages/manager/calendar/MainCalendar";

import OverviewClinics from "../pages/manager/clinic/OverviewClinics";
import RegisterClinic from "../pages/manager/clinic/RegisterClinic";
import EditClinic from "../pages/manager/clinic/EditClinic";

import OverviewPhysiotherapists from "../pages/manager/physioterapists/OverviewPhysiotherapists";
import RegisterPhysiotherapist from "../pages/manager/physioterapists/RegisterPhysiotherapist";
import EditPhysiotherapist from "../pages/manager/physioterapists/EditPhysiotherapist";

import OverviewServices from "../pages/manager/services/OverviewServices";
import RegisterSessionType from "../pages/manager/services/RegisterSessionType";
import RegisterClassType from "../pages/manager/services/RegisterClassType";
import RegisterVoucher from "../pages/manager/services/RegisterVoucher";
import EditSessionType from "../pages/manager/services/EditSessionType";
import EditClassType from "../pages/manager/services/EditClassType";
import EditVoucher from "../pages/manager/services/EditVoucher";

import RegisterPatient from "../pages/manager/patients/RegisterPatient";
import OverviewPatients from "../pages/manager/patients/OverviewPatients";
import PatientCard from "../pages/manager/patients/PatientCard";

import RegisterAcquiredVoucher from "../pages/manager/services/RegisterAcquiredVoucher";
import AcquiredVoucherCard from "../pages/manager/services/AcquiredVoucherCard";

import OverviewCashRegister from "../pages/manager/cashRegister/OverviewCashRegister";
import CashRegisterCard from "../pages/manager/cashRegister/CashRegisterCard";

import OverviewTasks from "../pages/manager/task/OverviewTasks";
import RegisterTask from "../pages/manager/task/RegisterTask";
import TaskCard from "../pages/manager/task/taskCard";

import PaymentError from "../pages/manager/paymentGateway/PaymentError";

import InvoiceTemplateTutorial from "../pages/docs/InvoiceTemplateTutorial"

import CalendarBookings from "../pages/public/bookings/CalendarBookings";
import EditUnconfirmedBooking from "../pages/public/bookings/EditUnconfirmedBooking";
import DisplayClinicBookings from "../pages/public/bookings/DisplayClinicBookings";
import AboutUsBookings from "../pages/public/bookings/AboutUsBookings";
import InfoBookings from "../pages/public/bookings/InfoBookings";

const AppRouter = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const isCalendarPath = location.pathname.includes("/agenda");

	const { store, persistor } = getStore(navigate);

	useEffect(() => {
		document.getElementById("overlay").style.display = "none";
		const element = document.getElementById("pin-top");
		element?.scrollIntoView();
	}, [location.pathname]);

	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<AxiosProvider />
				<CookiesConfirm />
				<MessagesDisplay />

				<div className={`flex flex-col overflow-x-hidden ${isCalendarPath ? "h-screen" : "min-h-screen"}`}>
					<div>
						<Navbar />
					</div>

					<div
						className={`${isCalendarPath ? "grow justify-between" : "flex-1"} overflow-auto flex flex-col overflow-x-hidden  scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar-thin  scrollbar-thumb-base-300 scrollbar-track-base-100`}
					>
						<div className={`${isCalendarPath ? "grow" : "flex-1"}`}>
							<div id="pin-top" />
							<Routes>
								{/* Info */}
								<Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
								<Route path={"/cookies-policy"} element={<CookiesPolicy />} />
								<Route path={"/legal"} element={<Legal />} />
								<Route path={"/about-us"} element={<AboutUs />} />
								<Route path={"/info"} element={<LandingPage />} />
								<Route path={"/landing-page"} element={<LandingPage />} />
								<Route path={"/health-check"} element={<HealthCheck />} />
								<Route path={"/maintenance"} element={<Maintenance />} />

								{/* User management */}
								<Route
									path={"/"}
									element={
										<AuthRoute>
											<Login />
										</AuthRoute>
									}
								/>
								<Route
									path={"/login"}
									element={
										<AuthRoute>
											<Login />
										</AuthRoute>
									}
								/>
								<Route
									path={"/register"}
									element={
										<AuthRoute>
											<Register />
										</AuthRoute>
									}
								/>
								<Route
									path={"/forgotten-password"}
									element={
										<AuthRoute>
											<ForgottenPassword />
										</AuthRoute>
									}
								/>

								{/* Manager */}
								<Route
									path={"/profile"}
									element={
										<ProtectedRoute requiredPermissions={["manager"]}>
											<ManagerProfile />
										</ProtectedRoute>
									}
								/>

								{/* Payment */}
								<Route
									path={"/payment/error"}
									element={
										<ProtectedRoute requiredPermissions={["manager"]}>
											<PaymentError />
										</ProtectedRoute>
									}
								/>

								{/* Agenda */}
								<Route
									path={"/agenda"}
									element={
										<ProtectedRoute
											requiredPermissions={["manager", "clinic"]}
											redirectPath={"/login"}
										>
											<MainCalendar />
										</ProtectedRoute>
									}
								/>

								{/* Clinics */}
								<Route
									path={"/clinic"}
									element={
										<ProtectedRoute requiredPermissions={["manager"]}>
											<OverviewClinics />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/clinic/register"}
									element={
										<ProtectedRoute requiredPermissions={["manager"]}>
											<RegisterClinic />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/clinic/:clinic_id"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<EditClinic />
										</ProtectedRoute>
									}
								/>

								{/* Physios */}
								<Route
									path={"/physiotherapist"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<OverviewPhysiotherapists />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/physiotherapist/register"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<RegisterPhysiotherapist />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/physiotherapist/:physio_id"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<EditPhysiotherapist />
										</ProtectedRoute>
									}
								/>

								{/* Services */}
								<Route
									path={"/services"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<OverviewServices />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/services/group-session-type/register"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<RegisterClassType />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/services/group-session-type/:group_session_type_id"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<EditClassType />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/services/session-type/register"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<RegisterSessionType />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/services/session-type/:session_type_id"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<EditSessionType />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/services/voucher/register"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<RegisterVoucher />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/services/voucher/:voucher_type_id"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<EditVoucher />
										</ProtectedRoute>
									}
								/>

								{/* Acquired vouchers */}
								<Route
									path={"/acquired-voucher/register"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<RegisterAcquiredVoucher />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/acquired-voucher/:acquired_voucher_id"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<AcquiredVoucherCard />
										</ProtectedRoute>
									}
								/>

								{/* Patients */}
								<Route
									path={"/patient"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<OverviewPatients />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/patient/register"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<RegisterPatient />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/patient/:patient_id"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<PatientCard />
										</ProtectedRoute>
									}
								/>

								{/* Cash registers and invoices */}
								<Route
									path={"/cash-register"}
									element={
										<ProtectedRoute requiredPermissions={["manager"]}>
											<OverviewCashRegister />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/cash-register/:cash_register_id"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<CashRegisterCard />
										</ProtectedRoute>
									}
								/>

								{/* Task */}
								<Route
									path={"/task"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<OverviewTasks />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/task/register"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<RegisterTask />
										</ProtectedRoute>
									}
								/>
								<Route
									path={"/task/:task_id"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<TaskCard />
										</ProtectedRoute>
									}
								/>

								{/* tutorials */}
								<Route
									path={"/docs/invoice-template"}
									element={
										<ProtectedRoute requiredPermissions={["manager", "clinic"]}>
											<InvoiceTemplateTutorial />
										</ProtectedRoute>
									}
								/>

								{/* online bookings */}
								<Route
									path={"/reservation/agenda"}
									element={<CalendarBookings />}
								/>
								<Route
									path={"/reservation/session"}
									element={<EditUnconfirmedBooking />}
								/>
								<Route
									path={"/reservation/clinic"}
									element={<DisplayClinicBookings />}
								/>
								<Route
									path={"/reservation/about-us"}
									element={<AboutUsBookings />}
								/>
								<Route
									path={"/reservation/info"}
									element={<InfoBookings />}
								/>
								<Route
									path={"/reservation/health-check"}
									element={<HealthCheck />}
								/>
								<Route
									path={"/reservation/privacy-policy"}
									element={<PrivacyPolicy />}
								/>
								<Route
									path={"/reservation/legal"}
									element={<Legal />}
								/>
								<Route
									path={"/reservation/cookies-policy"}
									element={<CookiesPolicy />}
								/>

								{/* Not found */}
								<Route path="*" element={<NotFound />} />
							</Routes>
						</div>

						<Footer />
					</div>
				</div>
			</PersistGate>
		</Provider>
	);
};

export default AppRouter;
