import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCaretDown,
    faCaretUp,
    faFilter,
    faFilterCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import { isDefined } from "../../../../auxiliar/formatValidators";

const Collapse = ({
    isMainTitle,
    title,
    children,
    faModality = "dropdown",
    onStateChange,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleCollapse = () => {
        onStateChange?.(!isOpen);
        setIsOpen(!isOpen);
    };

    const icon = getIcon(isOpen, faModality);

    return (
        <details className="collapse">
            <summary
                onClick={toggleCollapse}
                className={`${isMainTitle ? "text-2xl" : "collapse-title text-xl font-medium"} text-primary cursor-pointer`}
            >
                {isMainTitle ? (
                    <h2 className="mt-1">
                        {isDefined(title) ? title : ""}
                        <FontAwesomeIcon icon={icon} className="ml-3 mb-1 text-base" />
                    </h2>
                ) : (
                    <>
                        {isDefined(title) ? title : ""}
                        <FontAwesomeIcon icon={icon} className="ml-3" />
                    </>
                )}
            </summary>
            <div className="collapse-content mx-1 text-md text-justify">
                {children}
            </div>
        </details>
    );
};

const getIcon = (isOpen, faModality) => {
    switch (faModality) {
        case "dropdown":
            return isOpen ? faCaretUp : faCaretDown;
        case "filter":
            return isOpen ? faFilterCircleXmark : faFilter;
        default:
            return faCaretDown;
    }
};

export default Collapse;
