import { useState } from "react";
import { useDispatch } from "react-redux";

import { dispatchMessageSave } from "../../store/reducers/messageReducer";
import { handleSubmit } from "../../auxiliar/auxFunctions";

const ShowClinicBookings = ({ code }) => {
	const path = `${process.env.REACT_APP_APP_URL}/reservation/agenda?clinic=${code}`

	const dispatch = useDispatch();
	const [copied, setCopied] = useState(false);

	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(path)
			.then(() => {
				setCopied(true);
				dispatchMessageSave(dispatch, {
					msg: "¡Enlace copiado al portapapeles! Ya puedes enviárselo a tus pacientes",
					type: "info",
				});
			})
			.catch(() => {
				dispatchMessageSave(dispatch, {
					msg: "Inténtalo de nuevo, ha habido un error al copiar el enlace",
					type: "error",
				});
			});
	};

	return (
		<div className="my-5">
			<h3 className="text-primary text-2xl">Reservas Online</h3>

			<p className="lg:text-lg my-3">
				Puedes enviar el siguiente enlace a los pacientes para que hagan
				reservas online. Para copiarlo, haz click en el botón de abajo.
			</p>

			<p className="lg:text-lg my-3">
				Accediendo al enlace podrán ver los huecos libres (no se mostrará
				información personal) y podrán solicitar una sesión. Cuando lo hagan, la
				clínica recibirá un aviso por correo electrónico y la solicitud
				aparecerá en el calendario. Es necesario que la clínica confirme la
				cita, por lo que seguirás teniendo control absoluto sobre tu agenda.
			</p>

			<div className="flex flex-col items-center">
				<button
					className={`btn ${copied ? "btn-primary" : "btn-secondary"}`}
					onClick={handleSubmit(copyToClipboard)}
				>
					{copied ? "¡Copiado!" : "Copiar enlace"}
				</button>
			</div>
		</div>
	);
};

export default ShowClinicBookings;
