import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

import PaginatorComponent from "../../../components/general/auxiliar/PaginatorComponent";
import CustomForm from "../../../components/general/form/CustomForm";
import Collapse from '../../../components/general/auxiliar/collapse/Collapse';
import useFormWithDisable from "../../../auxiliar/customHooks/useFormWithDisable";
import usePaginationFilterEffects from "../../../auxiliar/customHooks/usePaginationFilterEffects";
import { getAllPhysiotherapists } from "../../../actions/physiotherapist";
import { handleSubmit } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";
import { colors } from "../../../auxiliar/colors";

const OverviewPhysiotherapists = () => {
	const navigate = useNavigate();

	const { register, watch, errors } = useFormWithDisable();

	const { id: clinicId } = useSelector((state) => state.activeClinic);

	const [isCollapseOpen, setIsCollapseOpen] = useState(false);
	const [physios, setPhysios] = useState({ waiting: true });
	const [filter, setFilter] = useState({
		clinic_id: clinicId,
		order: "name",
		per_page: 10,
		page: 1,
	});

	const writtenFilter = {
		name: watch("name") ?? "",
		surnames: watch("surnames") ?? "",
		only_active: watch("only_active") ?? true,
	};

	const disabled = usePaginationFilterEffects(
		filter,
		isCollapseOpen ? writtenFilter : { only_active: true },
		getAllPhysiotherapists,
		setPhysios,
		setFilter
	);

	const formConfig = {
		name: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Nombre",
			placeholder: "Nombre",
			name: "name",
		},
		surnames: {
			condition: true,
			disabled: false,
			type: "text",
			label: "Apellidos",
			placeholder: "Apellidos",
			name: "surnames",
		},
		only_active: {
			condition: true,
			disabled: false,
			defaultValue: true,
			type: "checkbox",
			label: "Excluir inactivos",
			name: "only_active",
		},
	};

	return (
		<div className="sm:container p-3 mx-auto my-5">
			<Collapse isMainTitle={true} title="Fisioterapeutas" faModality="filter" onStateChange={setIsCollapseOpen}>
				<form className="mt-3">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-3">
						<CustomForm
							formConfig={formConfig}
							errors={errors}
							register={register}
						/>
					</div>
				</form>
			</Collapse>

			<div className="sm:container p-3 mx-auto my-2">
				{physios.waiting ? (
					<div className="flex items-center justify-center">
						<span className="loading loading-dots loading-lg" />
					</div>
				) : !isDefined(physios.order) ||
					!isDefined(physios.content) ||
					physios.order.length <= 0 ? (
					<p className="lg:text-lg my-3">
						No hay fisioterapeutas registrados, añade el primero haciendo click en
						el botón de abajo.
					</p>
				) : (
					<div className="overflow-x-auto overflow-y-hidden">
						<table className="table">
							<thead>
								<tr>
									<th>Nombre</th>
									<th className="hidden lg:table-cell">Teléfono</th>
									<th className="hidden md:table-cell">Color</th>
									<th className="hidden sm:table-cell text-center">Activo</th>
								</tr>
							</thead>

							<tbody>
								{physios.order.map((physioId) => {
									const physio = physios.content && physios.content[physioId];

									return (
										<tr key={physioId}>
											<td>{`${physio.name} ${physio.surnames}`}</td>
											<td className="hidden lg:table-cell">
												{physio.phone_number}
											</td>
											<td className="hidden md:table-cell">
												{colors[physio.color]}
											</td>
											<td className="hidden sm:table-cell text-center">
												<FontAwesomeIcon
													icon={physio.active ? faCheck : faXmark}
													className="text-primary"
												/>
											</td>
											<td className="text-center">
												<button
													type="button"
													className="btn btn-primary btn-sm"
													onClick={handleSubmit(
														navigate,
														`/physiotherapist/${physioId}`
													)}
												>
													Detalles
												</button>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				)}

				<form className="mt-5 flex justify-between">
					<div className="flex space-x-2 sm:space-x-4">
						<button
							className="btn btn-primary btn-sm"
							onClick={handleSubmit(navigate, "/physiotherapist/register")}
						>
							Nuevo fisioterapeuta
						</button>
					</div>

					<PaginatorComponent
						pagination={physios.pagination}
						setFilter={setFilter}
						filter={filter}
						disabled={disabled}
					/>
				</form>
			</div>
		</div>
	);
};

export default OverviewPhysiotherapists;
