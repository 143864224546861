import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";

import useIsStandalone from "../../../auxiliar/customHooks/useIsStandalone";

const FormSelector = ({ customKey, register, config, errors }) => {
	const isStandalone = useIsStandalone()

	return (
		<div key={customKey} className={`form-control flex flex-col ${config.classname}`}>
			{config.label && (
				<label htmlFor={config.name} className="label-text">
					{config.label}{" "}

					{// This will only render to show an info icon
						config.info && (
							<div className="tooltip tooltip-right" data-tip={config.info}>
								<FontAwesomeIcon icon={faCircleInfo} className="text-primary" />
							</div>
						)
					}

					{// This will only render to redirect to another entity
						config.redirect && (
							<a href={config.redirect} target={isStandalone ? "_self" : "_blank"} className="mx-1">
								<FontAwesomeIcon icon={faArrowUpRightFromSquare} className="text-primary" />
							</a>
						)
					}
				</label>
			)}
			<div className={`${errors ? "tooltip" : ""}`} data-tip={errors?.message}>
				<select
					className={`select text-left w-full disabled:bg-disabled disabled:text-disabled-content 
						${errors ?
							"select-error disabled:select-error" :
							"select-primary"
						}`
					}
					disabled={config.disabled}
					defaultValue={config.defaultValue}
					name={config.name}
					id={config.name}
					{...register(config.name, config.validators ? config.validators : {
						required: false,
						validate: false,
					})}
				>
					{config.options.map((elem) => {
						return (
							<option key={elem.key} value={elem.key}>
								{elem.label}
							</option>
						);
					})}
				</select>
			</div>
		</div>
	);
};

export default FormSelector;
