import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormSelector from "./FormSelector";
import { fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";
import { getAllVouchers } from "../../../actions/voucher";

const CustomVoucherSelector = ({
	metadata,
	customKey,
	register,
	config,
	errors,
}) => {
	const dispatch = useDispatch();
	const clinicId = useSelector((state) => state.activeClinic.id);
	const [vouchers, setVouchers] = useState({ content: {}, order: [], waiting: true });

	useEffect(() => {
		fetchData(
			dispatch,
			getAllVouchers,
			{
				clinic_id: clinicId,
				order: "name",
				per_page: 1000,
				page: 1,
				...(config.defaultValue && !["select"].includes(config.defaultValue) && { selected: config.defaultValue }),
			},
			setVouchers
		);
	}, []);

	const checkVoucherSelected = (value) => {
		if (config.name.includes('tmp')) return;

		if (!isDefined(value) || value === "select") {
			return "El tipo de bono es obligatorio";
		}
	};

	const getDefault = () => {
		if (isDefined(config.defaultValue) && metadata) {
			const v = vouchers.content[config.defaultValue];
			return `${v.voucher_type_id}-${v.total_sessions}-${v.validity_period_unit}-${v.validity_periods}-${Number(v.price).toFixed(2)}`;
		}

		return config?.defaultValue?.toString() || "select";
	}

	const options = [
		{ key: "select", label: "Selecciona un tipo de bono" },
		...(vouchers?.order
			? vouchers?.order?.map((id) => {
				const v = vouchers.content[id];
				return {
					key: metadata
						? `${v.voucher_type_id}-${v.total_sessions}-${v.validity_period_unit}-${v.validity_periods}-${Number(v.price).toFixed(2)}`
						: id,
					label: v.display_name,
				};
			}) : []),
	];

	if (vouchers.waiting) {
		return <div key={customKey + '-tmp'}>
			<FormSelector
				customKey={customKey + '-tmp'}
				register={register}
				config={{
					condition: config.condition,
					disabled: config.disabled,
					type: "selector",
					name: config.name + '-tmp',
					label: config.label,
					info: config.info,
					options: [{ key: config.defaultValue || options[0]?.key, label: "Cargando tipos de bono..." }],
					defaultValue: "loading",
				}}
				errors={undefined}
			/>
		</div>;
	} else if (vouchers?.order?.length === 0) {
		return (
			<FormSelector
				customKey={customKey + '-tmp'}
				register={register}
				config={{
					condition: config.condition,
					disabled: true,
					type: "selector",
					name: config.name,
					label: config.label,
					info: "Antes de seguir utilizando la app tienes que registrar tu primer tipo de bono haciendo click en el botón de la derecha",
					defaultValue: config.defaultValue,
					options: [{ key: config.defaultValue || options[0]?.key, label: "Registra tu primer tipo de bono" }],
					validators: { validate: () => "Registra un tipo de bono e inténtalo de nuevo" },
					redirect: '/services/voucher/register',
				}}
				errors={errors}
			/>
		)
	}
	else if (!metadata || !config.disabled) {
		return (
			<FormSelector
				customKey={customKey}
				register={register}
				config={{
					condition: config.condition,
					disabled: config.disabled,
					type: "selector",
					name: config.name,
					label: config.label,
					info: config.info,
					options: options,
					classname: config.classname,
					defaultValue: getDefault(),
					validators: { validate: checkVoucherSelected },
				}}
				errors={errors}
			/>
		);
	} else {
		return (
			<>
				<FormSelector
					customKey={customKey}
					register={register}
					config={{
						condition: true,
						disabled: false,
						type: "selector",
						name: config.name,
						label: config.label,
						info: config.info,
						options: options,
						classname: "hidden",
						defaultValue: getDefault(),
						validators: { validate: checkVoucherSelected },
					}}
					errors={errors}
				/>
				<FormSelector
					customKey={customKey + "-aux"}
					register={register}
					config={{
						condition: config.condition,
						disabled: config.disabled,
						type: "selector",
						name: config.name + "-aux",
						label: config.label,
						info: config.info,
						options: options,
						classname: config.classname,
						defaultValue: getDefault(),
					}}
					errors={errors}
				/>
			</>
		)
	}
};

export default CustomVoucherSelector;
