import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import FormSelector from "./FormSelector";
import useQueryParams from "../../../auxiliar/customHooks/useQueryParams";
import { getAllSessionTypes, getPublicSessionTypes } from "../../../actions/sessionTypes";
import { fetchData } from "../../../auxiliar/auxFunctions";
import { isDefined } from "../../../auxiliar/formatValidators";

const CustomSessionTypeSelector = ({ metadata, customKey, register, config, errors }) => {
	const params = useQueryParams();
	const dispatch = useDispatch();
	const clinicId = useSelector((state) => state.activeClinic.id);
	const [sessionTypes, setSessionTypes] = useState({ content: {}, order: [], waiting: true });

	useEffect(() => {
		fetchData(
			dispatch,
			params.has("clinic") ? getPublicSessionTypes : getAllSessionTypes,
			{
				clinic_id: params.get("clinic") || clinicId,
				order: "name",
				per_page: 1000,
				page: 1,
				...(config.defaultValue && !["select"].includes(config.defaultValue) && { selected: config.defaultValue }),
			},
			setSessionTypes
		);
	}, []);

	const checkSessionTypeSelected = (value) => {
		if (config.name.includes('tmp')) return
		if (!isDefined(value) || value === "select") {
			return "El tipo de sesión es obligatorio";
		}
	};

	const getDefault = () => {
		if (isDefined(config.defaultValue) && metadata) {
			const t = sessionTypes.content[config.defaultValue];
			return `${config?.defaultValue}-${t?.session_duration}-${t?.price}`;
		}

		return config?.defaultValue?.toString() || "select";
	}

	const options = [
		...(config.defaultValue ? [] :
			[{ key: "select", label: "Selecciona un tipo de sesión" }]
		),
		...(sessionTypes?.order
			? sessionTypes?.order?.map((id) => {
				const t = sessionTypes.content[id];
				return {
					key: metadata
						? `${id}-${t.session_duration}-${t.price}`
						: id.toString(),
					label: sessionTypes?.content?.[id]?.display_name,
				}
			})
			: []),
	];

	if (sessionTypes.waiting) {
		return <div key={customKey + '-tmp'}>
			<FormSelector
				customKey={customKey + '-tmp'}
				register={register}
				config={{
					condition: config.condition,
					disabled: config.disabled,
					type: "selector",
					name: config.name + '-tmp',
					label: config.label,
					info: config.info,
					options: [{ key: config.defaultValue || options[0]?.key, label: "Cargando tipos de sesión..." }],
					defaultValue: "loading",
				}}
				errors={undefined}
			/>
		</div>;
	} else if (sessionTypes?.order?.length === 0) {
		return (
			<FormSelector
				customKey={customKey + '-tmp'}
				register={register}
				config={{
					condition: config.condition,
					disabled: true,
					type: "selector",
					name: config.name,
					label: config.label,
					info: "Antes de seguir utilizando la app tienes que registrar tu primer tipo de sesión haciendo click en el botón de la derecha",
					defaultValue: config.defaultValue,
					options: [{ key: config.defaultValue || options[0]?.key, label: "Registra tu primer tipo de sesión" }],
					validators: { validate: () => "Registra un tipo de sesión e inténtalo de nuevo" },
					redirect: '/services/session-type/register',
				}}
				errors={errors}
			/>
		)
	} else if (!metadata || !config.disabled) {
		return (
			<FormSelector
				customKey={customKey}
				register={register}
				config={{
					condition: config.condition,
					disabled: config.disabled,
					type: "selector",
					name: config.name,
					label: config.label,
					info: config.info,
					options: options,
					classname: config.classname,
					defaultValue: getDefault(),
					validators: { validate: checkSessionTypeSelected },
				}}
				errors={errors}
			/>
		);
	} else {
		return (
			<>
				<FormSelector
					customKey={customKey}
					register={register}
					config={{
						condition: true,
						disabled: false,
						type: "selector",
						name: config.name,
						label: config.label,
						info: config.info,
						options: options,
						classname: "hidden",
						defaultValue: getDefault(),
						validators: { validate: checkSessionTypeSelected },
					}}
					errors={errors}
				/>
				<FormSelector
					customKey={customKey + "-aux"}
					register={register}
					config={{
						condition: config.condition,
						disabled: config.disabled,
						type: "selector",
						name: config.name + "-aux",
						label: config.label,
						info: config.info,
						options: options,
						classname: config.classname,
						defaultValue: getDefault(),
					}}
					errors={errors}
				/>
			</>
		)
	}
};

export default CustomSessionTypeSelector;
