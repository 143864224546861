import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const FormTextarea = ({ customKey, register, config, errors }) => {
	return (
		<div key={customKey} className="form-control flex flex-col col-span-1 md:col-span-2">
			{config.label && (
				<label htmlFor={config.name} className="label-text">
					{config.label}{" "}
					{config.info && (
						<div className="tooltip" data-tip={config.info}>
							<FontAwesomeIcon icon={faCircleInfo} className="text-primary" />
						</div>
					)}
				</label>
			)}

			<div className={`${errors ? "tooltip" : ""}`} data-tip={errors?.message}>
				<textarea
					className={`textarea text-left w-full disabled:bg-disabled disabled:text-disabled-content placeholder-disabled-content 
						${errors ?
							"textarea-error disabled:textarea-error" :
							"textarea-primary"
						}`
					}
					type={config.type}
					id={config.name}
					defaultValue={config.defaultValue}
					placeholder={config.placeholder}
					disabled={config.disabled}
					{...register(config.name, config.validators ? config.validators : {
						required: false,
						pattern: false,
						validate: false,
						minLength: 0,
						maxLength: Infinity,
					})}
				/>
			</div>
		</div>
	);
};

export default FormTextarea;
